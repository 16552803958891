<template>
  <div
    id="chatList"
    v-scroll:#chatList="listenScrollFetchMore"
    class="ps-chat-log h-full chat-log pa-5"
  >
    <div
      v-if="loading"
      class="d-flex align-center justify-center mt-4"
      style="height: 60vh"
    >
      <v-progress-circular
        indeterminate
        size="48"
        color="primary"
      />
    </div>
    <div
      v-else
    >
      <div
        class="d-flex my-2"
        style="min-height: 36px"
      >
        <v-progress-circular
          v-if="loadingFetchMore"
          indeterminate
          color="primary"
          class="mx-auto"
        />
      </div>
      <div
        v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
        :key="msgGrp.sender + String(index)"
      >
        <div
          v-if="index === 0 || formattedChatData.formattedChatLog[index === 0 ? 0 : index - 1].date !== msgGrp.date"
          class="d-flex justify-center pt-2 pb-4"
        >
          <span class="text--disabled">{{ msgGrp.date }}</span>
        </div>
        <div
          class="chat-group d-flex align-start"
          :class="[
            { 'flex-row-reverse': msgGrp.sender === 1 },
            { 'mb-8': formattedChatData.formattedChatLog.length - 1 !== index },
          ]"
        >
          <div
            v-if="msgGrp.sender === 1"
            class="chat-avatar ms-2"
          >
            <v-avatar
              size="38"
              color="v-avatar-light-bg"
            >
              <span>{{ avatarText(state.activeChat.value.data.workspaceToken.connection_name) }}</span>
              <!-- <v-img /> -->
            </v-avatar>
          </div>
          <div
            class="chat-body d-inline-flex flex-column"
            :class="msgGrp.sender === 1 ? 'align-end' : 'align-start'"
          >
            <span
              v-if="msgGrp.sender === 1"
              class="ms-auto text-caption mb-2"
              :class=" msgGrp.assigned_to && 'cursor-pointer'"
              @click=" msgGrp.assigned_to && $router.push({
                name: 'user-detail',
                params: { id: msgGrp.assigned_to.id }
              })"
            >{{ state.activeChat.value.data.workspaceToken.connection_name }} {{ ` ${msgGrp.assigned_to ? "/ " + msgGrp.assigned_to.name: ''}` }}</span>
            <div
              v-for="(msgData, msgIndex) in msgGrp.messages"
              :id="`chat-${msgData.id}`"
              :key="msgData.time"
            >
              <p
                v-if="msgData.type === 'text'"
                class="chat-content py-3 px-4 elevation-1"
                :class="[
                  msgGrp.sender === 2 ? 'bg-card chat-left' : 'primary white--text chat-right',
                  msgGrp.messages.length - 1 !== msgIndex ? 'mb-2' : 'mb-1',
                ]"
              >
                {{ msgData.msg }}
              </p>
              <div
                v-else-if="msgData.type === 'photo'"
                class="chat-content elevation-1"
                :class="[
                  msgGrp.sender === 2 ? 'bg-card chat-left' : 'primary white--text chat-right',
                  msgGrp.messages.length - 1 !== msgIndex ? 'mb-2' : 'mb-1',
                ]"
              >
                <v-img
                  v-intersect="fetchDocument(msgData)"
                  width="300px"
                  min-height="200px"
                  class="rounded-t cursor-pointer"
                  :class="{ 'rounded-b': !msgData.caption }"
                  :src="msgData.msg"
                  @click="previewImage(msgData.msg)"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        :color="msgGrp.sender === 2 ? 'primary' : 'white'"
                      />
                    </v-row>
                  </template>
                </v-img>
                <div
                  v-if="msgData.caption"
                  class="px-4 py-2"
                >
                  {{ msgData.caption }}
                </div>
              </div>
              <v-card
                v-else-if="msgData.type === 'document'"
                class="chat-content elevation-1 px-4 py-2 d-flex align-center"
                :class="[
                  msgGrp.sender === 2 ? 'bg-card chat-left' : 'primary white--text chat-right',
                  msgGrp.messages.length - 1 !== msgIndex ? 'mb-2' : 'mb-1',
                ]"
                @click="openDocument(msgData.msg)"
              >
                <v-icon
                  size="36px"
                  class="mr-2"
                  :class="msgGrp.sender === 2 ? '' : 'white--text'"
                >
                  {{ icons.mdiFile }}
                </v-icon>
                <div :class="msgGrp.sender === 2 ? '' : 'white--text'">
                  <span class="text-subtitle-2 d-block">{{ ellipsis(msgData.file_name, 24) }}</span>
                  <span class="text-caption">Document Details</span>
                </div>
                <v-icon
                  size="24px"
                  class="ml-4"
                  :class="msgGrp.sender === 2 ? 'text--disabled' : 'white--text'"
                >
                  {{ icons.mdiDownload }}
                </v-icon>
              </v-card>
            </div>
            <div :class="{ 'text-right': msgGrp.sender === 1 }">
              <span class="text-xs me-1 text--disabled">{{
                formatDate(msgGrp.messages[msgGrp.messages.length - 1].time, { hour: 'numeric', minute: 'numeric' })
              }}</span>
              <v-icon
                v-if="msgGrp.sender === 1"
                size="16"
                :color="resolveFeedbackIcon(msgGrp.messages[msgGrp.messages.length - 1].feedback).color"
              >
                {{ resolveFeedbackIcon(msgGrp.messages[msgGrp.messages.length - 1].feedback).icon }}
              </v-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { createFieldMapper } from 'vuex-use-fields'
import { mdiCheck, mdiFile, mdiDownload } from '@mdi/js'
import { formatDate, avatarText, ellipsis } from '@core/utils/filter'
import { showImages } from 'vue-img-viewr'
import { useDebounceFn } from '@vueuse/core'
import { apolloClient } from '@/vue-apollo'
import { chatList } from '@/graphql/queries'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'

const useFieldChat = createFieldMapper({ getter: 'chat/getField', setter: 'chat/setField' })

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const state = {
      ...useFieldChat(['activeChat', 'chatListOffset']),
    }

    const loadingFetchMore = ref(false)

    const formattedChatData = computed(() => {
      const activeChat = state.activeChat.value

      if (!activeChat) return null

      const contact = {
        id: activeChat.contact.id,
        avatar: activeChat.contact.photo,
      }

      let chatLog = []
      if (activeChat.chats.length) {
        chatLog = activeChat.chats
      }

      const formattedChatLog = []
      let chatMessagesender = chatLog[0] ? chatLog[0].sender.id : undefined
      let currentDate = chatLog[0] ? formatDate(chatLog[0].created_at) : undefined
      let msgGroup = {
        date: currentDate,
        sender: chatMessagesender,
        messages: [],
        assigned_to: null,
      }
      chatLog.forEach((msg, index) => {
        if (chatMessagesender === msg.sender.id && currentDate === formatDate(msg.created_at)) {
          msgGroup.assigned_to = msg.assign_user
          msgGroup.messages.push({
            id: msg.id,
            msg: msg.content,
            time: msg.created_at,
            type: msg.type,
            caption: msg.caption,
            file_name: msg.file_name,
            file_id: msg.file_id,
            feedback: null,
          })
        } else {
          chatMessagesender = msg.sender.id
          currentDate = formatDate(msg.created_at)
          formattedChatLog.push(msgGroup)
          msgGroup = {
            date: formatDate(msg.created_at),
            sender: msg.sender.id,
            assigned_to: msg.assign_user,
            messages: [
              {
                id: msg.id,
                msg: msg.content,
                time: msg.created_at,
                type: msg.type,
                caption: msg.caption,
                file_name: msg.file_name,
                file_id: msg.file_id,
                feedback: null,
              },
            ],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      return {
        formattedChatLog,
        contact,
      }
    })

    const previewImage = img => {
      showImages({
        urls: [img],
        index: 0,
      })
    }

    /* eslint-disable-next-line */
    const resolveFeedbackIcon = feedback => {
      // if (feedback.isSeen) return { icon: mdiCheckAll, color: 'success' }
      // if (feedback.isDelivered) return { icon: mdiCheckAll, color: null }
      return { icon: mdiCheck, color: null }
    }

    const scrollToBottom = () => {
      const el = document.getElementById('chatList')

      if (el) {
        el.scroll({ top: el.scrollHeight, behavior: 'smooth' })
      }
    }

    const fetchDocument = data => {
      if (!data.msg) {
        emit('fetchMedia', {
          chatId: data.id,
          fileId: data.file_id,
        })
      }
    }

    const listenScrollFetchMore = useDebounceFn(async data => {
      if (data.target.scrollTop <= 500) {
        console.log('fetch more')
        state.chatListOffset.value += 20

        loadingFetchMore.value = true
        apolloClient.query({
          query: chatList,
          variables: {
            room_id: state.activeChat.value.data.id,
            pagination: {
              limit: 20,
              offset: state.chatListOffset.value,
            },
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          loadingFetchMore.value = false
          state.activeChat.value.chats = [
            ...result.data.chatList.chatList,
            ...state.activeChat.value.chats,
          ]
        }).catch(err => {
          console.log(err)
          errorHandling(err)
          loadingFetchMore.value = false
        })
      }
    }, 200)

    return {
      loadingFetchMore,
      state,
      formattedChatData,
      formatDate,
      avatarText,
      ellipsis,
      resolveFeedbackIcon,
      scrollToBottom,
      fetchDocument,
      previewImage,
      listenScrollFetchMore,

      icons: {
        mdiFile,
        mdiDownload,
      },
    }
  },
}
</script>

<style>

</style>
