<template>
  <div>
    <div class="d-flex align-center mt-2">
      <span class="text-caption font-weight-bold ms-4 me-2">Agent</span>
      <v-divider class="my-4" />
    </div>
    <div class="px-4 d-flex flex-column align-start">
      <span
        class="text-caption"
        :class="activeChat.data.agent ? 'font-medium primary--text' : 'text--disabled'"
      >
        {{
          activeChat.data.agent
            ? `${activeChat.data.agent.first_name} ${activeChat.data.agent.last_name}`
            : 'Belum ada agent. Tugaskan pengguna untuk menangani chat ini.'
        }}
      </span>
      <v-menu
        :close-on-content-click="false"
        bottom
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            outlined
            x-small
            rounded
            class="mt-2"
            v-bind="attrs"
            v-on="on"
          >
            <template #loader>
              <v-progress-circular
                size="16"
                width="2"
                indeterminate
              />
            </template>
            Tugaskan Pengguna
          </v-btn>
        </template>
        <v-card
          width="240px"
          class="pa-2"
        >
          <UserAutoSuggest
            v-model="selectedAgent"
            label="Pilih agent"
          />
          <v-btn
            class="mt-2"
            color="primary"
            rounded
            block
            :loading="loadingAssignAgent"
            small
            @click="assignAgent"
          >
            {{ activeChat.data.agent ? 'Update Agent' : 'Tambah Agent' }}
          </v-btn>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { apolloClient } from '@/vue-apollo'
import { assignAgent as assignAgentMutation } from '@/graphql/mutations'
import UserAutoSuggest from '@/components/inputs/UserAutoSuggest.vue'
import store from '@/store'

export default {
  components: {
    UserAutoSuggest,
  },
  props: {
    activeChat: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const selectedAgent = ref(null)
    const loadingAssignAgent = ref(false)
    const assignAgent = () => {
      if (selectedAgent.value) {
        loadingAssignAgent.value = true
        apolloClient
          .mutate({
            mutation: assignAgentMutation,
            variables: {
              room_id: props.activeChat.data.id,
              agent_id: selectedAgent.value.id,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          })
          .then(() => {
            selectedAgent.value = null
            loadingAssignAgent.value = false

            emit('success')
          })
      }
    }

    return {
      selectedAgent,
      loadingAssignAgent,
      assignAgent,
    }
  },
}
</script>

<style>

</style>
