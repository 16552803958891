import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center mt-2"},[_c('span',{staticClass:"text-caption font-weight-bold ms-4 me-2"},[_vm._v("Agent")]),_c(VDivider,{staticClass:"my-4"})],1),_c('div',{staticClass:"px-4 d-flex flex-column align-start"},[_c('span',{staticClass:"text-caption",class:_vm.activeChat.data.agent ? 'font-medium primary--text' : 'text--disabled'},[_vm._v(" "+_vm._s(_vm.activeChat.data.agent ? ((_vm.activeChat.data.agent.first_name) + " " + (_vm.activeChat.data.agent.last_name)) : 'Belum ada agent. Tugaskan pengguna untuk menangani chat ini.')+" ")]),_c(VMenu,{attrs:{"close-on-content-click":false,"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mt-2",attrs:{"color":"primary","outlined":"","x-small":"","rounded":""},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c(VProgressCircular,{attrs:{"size":"16","width":"2","indeterminate":""}})]},proxy:true}],null,true)},'v-btn',attrs,false),on),[_vm._v(" Tugaskan Pengguna ")])]}}])},[_c(VCard,{staticClass:"pa-2",attrs:{"width":"240px"}},[_c('UserAutoSuggest',{attrs:{"label":"Pilih agent"},model:{value:(_vm.selectedAgent),callback:function ($$v) {_vm.selectedAgent=$$v},expression:"selectedAgent"}}),_c(VBtn,{staticClass:"mt-2",attrs:{"color":"primary","rounded":"","block":"","loading":_vm.loadingAssignAgent,"small":""},on:{"click":_vm.assignAgent}},[_vm._v(" "+_vm._s(_vm.activeChat.data.agent ? 'Update Agent' : 'Tambah Agent')+" ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }