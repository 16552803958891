<template>
  <v-card
    class="pa-2 mr-2 d-flex flex-column align-center"
    @click="$emit('click', $event)"
  >
    <v-tooltip
      top
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          size="40"
          v-bind="attrs"
          v-on="on"
        >
          {{ icon }}
        </v-icon>
      </template>
      <span>{{ label }}</span>
    </v-tooltip>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
}
</script>

<style>

</style>
