<template>
  <div>
    <v-dialog
      v-model="isOpen"
      max-width="700"
      scrollable
      @input="!$event ? reset() : null"
    >
      <v-card>
        <div class="d-flex justify-space-between pa-4">
          <span class="text-h6 font-medium primary--text">Konfirmasi Hapus {{ formName }}</span>
          <v-icon
            @click="close()"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </div>
        <v-card-text class="pt-2 job-form-content">
          <v-card
            class="mb-5 px-5"
            flat
            outlined
          >
            <v-radio-group v-model="updateOption">
              <v-radio
                :value="'auto'"
              >
                <template v-slot:label>
                  <div>Pindah semuanya ke {{ formName }} <strong>{{ jobStatusList[0].name }}</strong></div>
                </template>
              </v-radio>
              <v-radio
                :value="'manual'"
                label="Pilih Manual"
              />
            </v-radio-group>
          </v-card>
          <v-form
            ref="confirmationForm"
            :disabled="updateOption === 'auto'"
            lazy-validation
            @submit.prevent="submitForm"
          >
            <div class="mb-5 pb-5">
              <v-row>
                <v-col
                  cols="6"
                >
                  {{ formName }} Lama
                </v-col>
                <v-col
                  cols="6"
                >
                  {{ formName }} Baru
                </v-col>
              </v-row>
              <v-divider class="mt-2 mb-5" />
              <v-row
                v-for="status in deleteStatusList"
                :key="status.id"
                class="mt-3"
              >
                <v-col
                  cols="6"
                  class="my-auto"
                >
                  <div class="d-flex">
                    <div
                      class="pa-3 mr-2 rounded"
                      :style="`background-color: ${status.color}`"
                    />
                    <span>
                      {{ status.name }}
                    </span>
                    <span class="ms-auto">
                      {{ status.count }} job
                      <v-icon>
                        {{ icons.mdiArrowRight }}
                      </v-icon>
                    </span>
                  </div>
                </v-col>
                <v-col
                  cols="6"
                >
                  <v-select
                    v-model="status.newStatus"
                    :items="jobStatusList"
                    item-text="name"
                    item-value="id"
                    :rules="[required]"
                    outlined
                    hide-details="auto"
                    :placeholder="`Pilih ${formName}`"
                    dense
                  />
                </v-col>
              </v-row>
            </div>
            <div class="d-flex flex-column flex-md-row mt-4">
              <v-btn
                class="mb-2 mb-md-0 mr-md-2 ml-md-auto"
                outlined
                @click="reset()"
              >
                Reset Form
              </v-btn>
              <v-btn
                color="primary"
                @click="submitForm()"
              >
                Konfirmasi
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import { mdiClose, mdiArrowRight } from '@mdi/js'
import { required } from '@core/utils/validation'
import { apolloClient } from '@/vue-apollo'
import { confirmDeleteStatus, confirmDeletePriority } from '@/graphql/mutations'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'

export default {
  setup(props, { emit }) {
    const isOpen = ref(false)
    const deleteStatusList = ref(null)
    const updateOption = ref('manual')
    const confirmationForm = ref(null)
    const jobStatusList = ref(null)
    const formName = ref(null)

    const show = (deleteList, statusList, name) => {
      isOpen.value = true
      deleteStatusList.value = deleteList
      jobStatusList.value = statusList
      formName.value = name
    }

    const close = () => {
      isOpen.value = false
    }

    const reset = () => {
      updateOption.value = 'manual'
      confirmationForm.value.reset()
    }

    const submitForm = async () => {
      if (updateOption.value === 'auto' || confirmationForm.value.validate()) {
        await apolloClient.mutate({
          mutation: formName.value === 'Status' ? confirmDeleteStatus : confirmDeletePriority,
          variables: {
            delete_id: deleteStatusList.value.map(status => status.id),
            default_id: updateOption.value === 'auto' ? jobStatusList.value[0].id : null,
            workspace_id: store.getters.getCurrentWorkspaceId,
            new_id: updateOption.value === 'manual' ? deleteStatusList.value.map(status => status.newStatus) : null,
          },
        }).then(() => {
          Vue.notify({
            title: 'Sukses',
            text: `${formName.value} job berhasil dihapus & job berhasil dipindahkan`,
          })
          emit('success')
          close()
        }).catch(err => {
          errorHandling(err)
        })
      }
    }

    return {
      isOpen,
      formName,
      updateOption,
      jobStatusList,
      deleteStatusList,
      confirmationForm,

      show,
      close,
      reset,
      required,
      submitForm,

      icons: {
        mdiClose,
        mdiArrowRight,
      },
    }
  },
}
</script>
<style>
</style>
