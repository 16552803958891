<template>
  <v-card class="max-h-content-container app-chat position-relative overflow-hidden text-sm">
    <v-navigation-drawer
      v-model="isLeftSidebarOpen"
      width="320"
      touchless
      mobile-breakpoint="sm"
      :temporary="$vuetify.breakpoint.xsOnly"
      absolute
      class="left-sidebar"
    >
      <chat-left-sidebar
        @close-left-sidebar="isLeftSidebarOpen = false"
        @open-chat="$router.replace({ name: 'chat-detail', params: { id: $event.id } }); openChat($event.id)"
      />
    </v-navigation-drawer>
    <div class="d-flex h-full">
      <div
        class="chat-content-area h-full d-flex flex-column w-100"
        :style="{
          'margin-left': isLeftSidebarOpen && $vuetify.breakpoint.mdAndUp ? '320px' : null,
          'margin-right': isRightSidebarOpen && $vuetify.breakpoint.mdAndUp ? '320px' : null,
        }"
        @dragover="onDragOver"
        @dragleave="onDragLeave"
        @drop="onDrop"
      >
        <!-- Active Chat -->
        <div
          v-if="(state.activeChat.value && state.activeChat.value.contact) || loadingActiveChat"
          class="h-full"
        >
          <!-- Navbar -->
          <div
            class="d-flex align-center px-5 py-4"
            style="height: 70px"
          >
            <!-- Sidebar Toggler, Avatar & Name -->
            <div class="d-flex align-center">
              <v-btn
                v-show="!isLeftSidebarOpen"
                icon
                class="me-2 d-inline-block d-md-none"
                @click="isLeftSidebarOpen = true"
              >
                <v-icon>{{ icons.mdiMenu }}</v-icon>
              </v-btn>
            </div>
            <div
              v-if="state.activeChat.value && state.activeChat.value.contact"
              class="d-flex cursor-pointer"
              @click="state.activeChat.value.contact.customer ? $router.push({ name: 'customer-detail', params: { id: state.activeChat.value.contact.customer.id } }) : null"
            >
              <v-avatar
                size="38"
                class="v-avatar-light-bg cursor-pointer me-3"
              >
                <v-img
                  v-if="state.activeChat.value.contact.avatar"
                  :src="state.activeChat.value.contact.avatar"
                />
                <span v-else>{{ avatarText(state.activeChat.value.contact.customer ? state.activeChat.value.contact.customer.name: state.activeChat.value.contact.name) }}</span>
              </v-avatar>
              <div class="d-flex flex-column">
                <p class="mb-0 text--primary font-weight-medium">
                  {{ state.activeChat.value.contact.customer ? state.activeChat.value.contact.customer.name: state.activeChat.value.contact.name }}
                </p>
                <span class="text--disabled text-xs">Telegram</span>
              </div>
            </div>
            <!-- Active Chat Actions -->
            <div class="ms-auto d-flex align-center">
              <v-btn
                v-if="state.activeChat.value && state.activeChat.value.data && state.activeChat.value.data.status.id === 3"
                color="primary"
                outlined
                small
                rounded
                class="mr-2"
                :loading="loadingResolve"
                @click="resolveChat"
              >
                <template #loader>
                  <v-progress-circular
                    size="16"
                    width="2"
                    indeterminate
                  />
                </template>
                Resolve
              </v-btn>
              <v-btn
                icon
                class="ms-2"
                @click="isRightSidebarOpen = !isRightSidebarOpen"
              >
                <v-icon>
                  {{ isRightSidebarOpen ? icons.mdiChevronDoubleRight : icons.mdiChevronDoubleLeft }}
                </v-icon>
              </v-btn>
            </div>
          </div>

          <v-divider />

          <chat-list
            ref="refChatList"
            :loading="loadingActiveChat"
            @fetchMedia="fetchMedia"
          />

          <!-- <perfect-scrollbar
            ref="refChatLogPS"
           :options="perfectScrollbarOptions"
            class="ps-chat-log h-full"
          >
            <chat-log
              ref="chatlog"
              :chat-data="state.activeChat.value"
              :profile-user-avatar="''"
              :loading="loadingActiveChat"
              @fetchMedia="fetchMedia"
            />
          </perfect-scrollbar> -->
        </div>

        <!-- Start Conversation -->
        <div
          v-else
          class="h-full"
        >
          <div
            v-if="!isLeftSidebarOpen"
            class="d-flex align-center justify-space-between px-5 py-4"
            style="height: 70px"
          >
            <!-- Sidebar Toggler, Avatar & Name -->
            <div class="d-flex align-center">
              <v-btn
                icon
                class="me-2 d-inline-block d-md-none"
              >
                <v-icon @click="isLeftSidebarOpen = true">
                  {{ icons.mdiMenu }}
                </v-icon>
              </v-btn>
            </div>
          </div>
          <div class="d-flex align-center justify-center h-full flex-grow-1 flex-column">
            <!-- Navbar -->
            <v-avatar
              size="109"
              class="elevation-3 mb-6 bg-card"
            >
              <v-icon
                size="50"
                class="rounded-0 text--primary"
              >
                {{ icons.mdiMessageTextOutline }}
              </v-icon>
            </v-avatar>
            <p
              class="mb-0 px-6 py-1 font-weight-medium text-lg elevation-3 rounded-xl text--primary bg-card"
              :class="[{ 'cursor-pointer': $vuetify.breakpoint.smAndDown }]"
            >
              Mulai Chat Dengan Oriens CRM
            </p>
          </div>
        </div>

        <chat-input
          v-if="!loadingActiveChat && state.activeChat.value && state.activeChat.value.contact"
          @fileInput="documents = [...documents, ...$event]"
          @clearFile="documents = []"
          @deleteFile="documents.splice($event, 1)"
        />
      </div>
      <v-navigation-drawer
        v-if="state.activeChat.value && state.activeChat.value.data"
        v-model="isRightSidebarOpen"
        width="320"
        touchless
        mobile-breakpoint="sm"
        :temporary="$vuetify.breakpoint.xsOnly"
        absolute
        class="left-sidebar"
        right
      >
        <chat-additional-information
          :loading="loadingActiveChat"
          @close-right-sidebar="isRightSidebarOpen = false"
          @refetch="openChat(state.activeChat.value.data.id, false)"
        />
      </v-navigation-drawer>
    </div>
  </v-card>
</template>

<script>
import {
  ref, onMounted, watchEffect, nextTick,
} from '@vue/composition-api'
import { createFieldMapper } from 'vuex-use-fields'
import {
  mdiMenu, mdiChevronDoubleRight, mdiChevronDoubleLeft,
  mdiCloseCircleOutline, mdiSendOutline, mdiMessageTextOutline,
} from '@mdi/js'
import { useResponsiveLeftSidebar } from '@core/comp-functions/ui'
import { avatarText } from '@core/utils/filter'
import { apolloClient } from '@/vue-apollo'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import ChatAdditionalInformation from './ChatAdditionalInformation.vue'
import ChatList from './ChatList.vue'
import ChatInput from './ChatInput.vue'
import { chatList, chatRooms, getTelegramLink } from '@/graphql/queries'
import {
  chatRoomNotif,
  chatUnreadCount,
  newChat,
} from '@/graphql/subscriptions'
import router from '@/router'
import dropFile from '@/utils/dropFile'
import { readTelegramChat, resolveChatRoom } from '@/graphql/mutations'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'

const useFieldChat = createFieldMapper({ getter: 'chat/getField', setter: 'chat/setField' })

export default {
  components: {
    ChatLeftSidebar,
    ChatAdditionalInformation,
    ChatList,
    ChatInput,
  },

  setup() {
    const loadingActiveChat = ref(false)
    const { isLeftSidebarOpen, contentStyles } = useResponsiveLeftSidebar({ sidebarWidth: 320 })
    const isRightSidebarOpen = ref(false)
    const refChatList = ref()

    const state = {
      ...useFieldChat(['loadingChats', 'chatRoomList', 'activeChat', 'documents', 'chatRoomCount', 'chatListOffset']),
    }

    const fetchChatRooms = async (loading = true) => {
      if (loading) state.loadingChats.value = true
      await apolloClient.query({
        query: chatRooms,
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.chatRoomList.value = result.data.chatRooms.chatList
        state.chatRoomCount.value = result.data.chatRooms.count

        setTimeout(() => {
          state.loadingChats.value = false
        }, 1000)
      })
    }

    const readChat = (chatsId, roomId) => {
      if (chatsId.length) {
        apolloClient.mutate({
          mutation: readTelegramChat,
          variables: {
            message_id: chatsId,
            roomId,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        })
      }
    }

    const openChat = async (id, loading = true) => {
      await fetchChatRooms(loading)
      const data = state.chatRoomList.value.find(el => el.id === id)
      state.chatListOffset.value = 0

      if (data) {
        if (loading) loadingActiveChat.value = true
        apolloClient.query({
          query: chatList,
          variables: {
            room_id: id,
            pagination: {
              limit: 20,
              offset: 0,
            },
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          state.activeChat.value = {
            chats: result.data.chatList.chatList.sort((a, b) => new Date(Date.parse(a.created_at)) - new Date(Date.parse(b.created_at))),
            contact: data.profile,
            data,
            count: result.data.chatList.count,
          }
          readChat(
            result.data.chatList.chatList.filter(el => el.read !== null && el.read === 0).map(el => el.message_id),
            data.id,
          )
          setTimeout(() => {
            loadingActiveChat.value = false
            isRightSidebarOpen.value = true

            nextTick(() => {
              refChatList.value.scrollToBottom()
            })
          }, 1000)
        }).catch(err => {
          console.log(err)
          loadingActiveChat.value = false
        })
      }
    }

    const loadingResolve = ref(false)
    const resolveChat = () => {
      loadingResolve.value = true
      apolloClient
        .mutate({
          mutation: resolveChatRoom,
          variables: {
            room_id: state.activeChat.value.data.id,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        })
        .then(() => {
          loadingResolve.value = false
          openChat(state.activeChat.value.data.id, false)
        }).catch(err => {
          loadingResolve.value = false
          errorHandling(err)
        })
    }

    onMounted(async () => {
      if (!state.chatRoomList.value.length) {
        await fetchChatRooms()
      }

      if (router.currentRoute.params && router.currentRoute.params.id) {
        openChat(+router.currentRoute.params.id)
      }

      apolloClient
        .subscribe({
          query: chatUnreadCount,
          fetchPolicy: 'no-cache',
          variables: {
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        })
        .subscribe({
          next: response => {
            const contact = state.chatRoomList.value.find(c => c.id === response.data.chatUnreadCount.roomId)
            if (contact) contact.unreadMsg = response.data.chatUnreadCount.unreadMsg
          },
          error: err => {
            console.log(err)
          },
        })

      apolloClient
        .subscribe({
          query: chatRoomNotif,
          fetchPolicy: 'no-cache',
          variables: {
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        })
        .subscribe({
          next: data => {
            console.log('new update!', data)
            const contact = state.chatRoomList.value.find(c => c.id === data.data.chatRoomNotif.room.id)

            // updating chat room
            if (contact) contact.status = data.data.chatRoomNotif.room.status

            // updating chat room that are active
            if (state.activeChat.value && state.activeChat.value.data && state.activeChat.value.data.id === data.data.chatRoomNotif.room.id) {
              state.activeChat.value = {
                ...state.activeChat.value,
                contact: data.data.chatRoomNotif.room.profile,
                data: data.data.chatRoomNotif.room,
              }
            }

            // push new chat room
            if (!contact) {
              state.chatRoomList.value = [
                data.data.chatRoomNotif.room,
                ...state.chatRoomList.value,
              ]
            }
          },
          error: err => {
            console.dir(err)
          },
        })

      apolloClient
        .subscribe({
          query: newChat,
          fetchPolicy: 'no-cache',
          variables: {
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        })
        .subscribe({
          next: data => {
            console.log('new chat subs', data)
            const { chatRoomList, activeChat } = state
            const newChatData = data.data.newChat
            const index = chatRoomList.value.findIndex(el => el.id === newChatData.room.id)

            if (index > -1) {
              chatRoomList.value[index].lastChat = {
                ...chatRoomList.value[index].lastChat,
                ...newChatData,
              }
            }

            if (activeChat.value && activeChat.value.data && activeChat.value.data.id === newChatData.room.id) {
              activeChat.value.chats.push(newChatData)

              readChat(
                activeChat.value.chats.filter(el => el.read !== null && el.read === 0).map(el => el.message_id),
                data.id,
              )

              nextTick(() => {
                refChatList.value.scrollToBottom()
              })
            }
          },
          error: err => {
            console.log(err)
          },
        })
    })

    const fetchMedia = ({ chatId, fileId }) => {
      const file = JSON.parse(fileId)
      apolloClient
        .query({
          query: getTelegramLink,
          variables: {
            file_id: file[Object.keys(file)[Object.keys(file).length - 1]].id,
            workspace_id: store.getters.getCurrentWorkspaceId,
            connection_name: state.activeChat.value.data.workspaceToken.connection_name,
          },
          fetchPolicy: 'no-cache',
        })
        .then(result => {
          const chat = state.activeChat.value.chats.find(el => el.id === chatId)
          if (chat) chat.content = result.data.getTelegramLink
        })
        .catch(err => {
          console.log(err)
        })
    }

    const {
      documents,
      onDragOver,
      onDrop,
      onDragLeave,
      onFileChange,
      deleteDoc,
    } = dropFile()

    watchEffect(() => {
      state.documents.value = documents.value
    })

    return {
      refChatList,

      // sidebar
      isLeftSidebarOpen,
      isRightSidebarOpen,
      contentStyles,

      state,
      loadingActiveChat,
      openChat,
      fetchMedia,
      loadingResolve,
      resolveChat,

      avatarText,

      icons: {
        mdiMenu,
        mdiChevronDoubleRight,
        mdiChevronDoubleLeft,
        mdiCloseCircleOutline,
        mdiSendOutline,
        mdiMessageTextOutline,
      },

      documents,
      onDragOver,
      onDrop,
      onDragLeave,
      onFileChange,
      deleteDoc,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/chat.scss';

.left-sidebar .v-navigation-drawer__content {
  overflow-y: hidden;
}

.dragBorder {
  border-color: var(--v-primary-base) !important;
  transition: all 0.3s ease;
}
</style>
