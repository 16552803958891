<template>
  <div
    v-click-outside="onClickOutside"
    class="chat-input w-full"
  >
    <div class="px-3 pb-3">
      <v-slide-y-transition
        mode="out-in"
      >
        <div
          v-if="!state.documents.value.length"
          :key="1"
        >
          <XyzTransitionGroup
            appear
            xyz="fade down-4 out-delay-0 stagger-2 out-stagger-0"
            class="d-flex mb-2"
          >
            <ChatAttachment
              v-if="showAttachmentMenu"
              :key="1"
              :icon="icons.mdiImage"
              label="Foto dan Video"
              @click="
                $refs.imgFilePicker.click()
              "
            />
            <ChatAttachment
              v-if="showAttachmentMenu"
              :key="2"
              :icon="icons.mdiFile"
              label="File dan Dokumen"
              @click="
                $refs.docFilePicker.click()
              "
            />
          </XyzTransitionGroup>
          <v-card>
            <v-form
              autocomplete="off"
              class="d-flex align-center"
              @submit.prevent="sendMessage"
            >
              <v-btn
                icon
                class="attachment-icon ml-2"
                @click="showAttachmentMenu = !showAttachmentMenu"
              >
                <v-icon size="22">
                  {{ icons.mdiPaperclip }}
                </v-icon>
              </v-btn>
              <v-text-field
                :key="state.activeChat.value.contact.id"
                v-model="chatInputMessage"
                placeholder="Ketik pesan..."
                solo
                flat
                class="flex-grow-0 msg-input w-full"
                hide-details
                height="48"
                autofocus
              />
              <v-btn
                color="primary"
                elevation="0"
                type="submit"
                fab
                x-small
                disable
                :loading="loadingSendChat"
                class="mr-2"
              >
                <v-icon>{{ icons.mdiSend }}</v-icon>
              </v-btn>
            </v-form>
          </v-card>
        </div>
        <div
          v-else
          :key="2"
        >
          <ChatAttachmentPreview
            v-model="chatInputMessage"
            :loading="loadingSendChat"
            @close="$emit('clearFile')"
            @delete="$emit('deleteFile', $event)"
            @send="sendMessage()"
          />
        </div>
      </v-slide-y-transition>
    </div>
    <input
      ref="docFilePicker"
      type="file"
      class="d-none"
      accept="*"
      multiple
      @change="onFileChange($event.target.files)"
    >
    <input
      ref="imgFilePicker"
      type="file"
      class="d-none"
      accept="image/*"
      multiple
      @change="onFileChange($event.target.files)"
    >
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from '@vue/composition-api'
import {
  mdiCloseCircleOutline, mdiSend, mdiPaperclip, mdiImage, mdiFile, mdiClose,
} from '@mdi/js'
import { createFieldMapper } from 'vuex-use-fields'
import { apolloClient } from '@/vue-apollo'
import { sendMessage as sendMessageMutation } from '@/graphql/mutations'
import ChatAttachment from './components/ChatAttachment.vue'
import ChatAttachmentPreview from './components/ChatAttachmentPreview.vue'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'

const useFieldChat = createFieldMapper({ getter: 'chat/getField', setter: 'chat/setField' })

export default {
  components: {
    ChatAttachment,
    ChatAttachmentPreview,
  },
  setup(_, { emit }) {
    const chatInputMessage = ref('')
    const showAttachmentMenu = ref(false)
    const loadingSendChat = ref(false)

    const state = {
      ...useFieldChat(['loadingChats', 'chatRoomList', 'activeChat', 'documents']),
    }

    const sendMessage = () => {
      if (!chatInputMessage.value && !state.documents.value.length) return

      const payload = {
        text: chatInputMessage.value,
        room_id: state.activeChat.value.data.id,
        type: 'text',
        file: null,
        workspace_id: store.getters.getCurrentWorkspaceId,
      }

      if (state.documents.value.length) {
        const isPhoto = state.documents.value.every(el => el.file.type.includes('image'))

        payload.type = isPhoto ? 'photo' : 'document'
        payload.file = state.documents.value.map(el => el.file)
      }

      loadingSendChat.value = true

      apolloClient
        .mutate({
          mutation: sendMessageMutation,
          variables: payload,
        })
        .then(() => {
          chatInputMessage.value = ''
          loadingSendChat.value = false
          emit('clearFile')
        }).catch(err => {
          errorHandling(err)
          loadingSendChat.value = false
        })
    }

    const onFileChange = files => {
      const documents = []

      for (let i = 0; i < files.length; i += 1) {
        const preview = URL.createObjectURL(files[i])
        documents.push({
          file: files[i],
          preview,
        })
      }

      emit('fileInput', documents)
    }

    const onClickOutside = () => {
      showAttachmentMenu.value = false
    }

    return {
      chatInputMessage,
      sendMessage,
      loadingSendChat,
      showAttachmentMenu,
      state,
      onFileChange,
      onClickOutside,

      icons: {
        mdiCloseCircleOutline,
        mdiSend,
        mdiPaperclip,
        mdiImage,
        mdiFile,
        mdiClose,
      },
    }
  },
}
</script>

<style>
.chat-input {
  position: absolute;
  bottom: 0;
  right: 0;
}

.attachment-icon {
  transform: rotate(45deg) !important;
}
</style>
