<template>
  <div>
    <div class="d-flex align-center">
      <span class="text-caption font-weight-bold ms-4 me-2">Informasi Pelanggan</span>
      <v-divider class="my-4" />
    </div>
    <div class="px-4 d-flex flex-column align-start">
      <v-card
        v-if="chatProps.contact.customer"
        outlined
        class="pa-2 w-full mb-2"
      >
        <router-link
          :to="{ name: 'customer-detail', params: { id: chatProps.contact.customer.id} }"
          class=" d-flex"
        >
          <v-avatar
            size="38"
            class="v-avatar-light-bg cursor-pointer me-3"
          >
            <v-img
              v-if="chatProps.contact.avatar"
              :src="chatProps.contact.avatar"
            />
            <span v-else>{{ avatarText(chatProps.contact.customer ? chatProps.contact.customer.name: chatProps.contact.name) }}</span>
          </v-avatar>
          <div class="d-flex flex-column">
            <p class="mb-0 text--primary font-weight-medium">
              {{ chatProps.contact.customer ? chatProps.contact.customer.name: chatProps.contact.name }}
            </p>
            <span class="text--disabled text-xs">Telegram</span>
          </div>
        </router-link>
      </v-card>
      <span
        v-if="!chatProps.contact.customer "
        class="text-caption text--disabled"
      >Belum disimpan. Simpan atau sambungkan dengan data pelanggan yang sudah ada.</span>
      <v-menu
        :close-on-content-click="false"
        bottom
        offset-y
        max-width="260px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            outlined
            x-small
            rounded
            class="mt-2"
            v-bind="attrs"
            v-on="on"
          >
            {{ chatProps.contact.customer ? 'Ubah Pelanggan' : 'Simpan Pelanggan' }}
          </v-btn>
        </template>
        <v-tabs-items
          v-model="tabCustomer"
        >
          <v-tab-item>
            <v-list nav>
              <v-list-item @click="tabCustomer = 1">
                <div class="d-flex flex-column pa-1">
                  <span class="text-subtitle-2 primary--text mb-1">{{ chatProps.contact.customer ? 'Hubungkan Pelanggan Lain' : 'Hubungkan Pelanggan' }}</span>
                  <span class="text-caption text--disabled">Hubungkan dengan salah satu dari daftar pelanggan yang sudah ada</span>
                </div>
              </v-list-item>
              <v-list-item @click="chatProps.contact.customer ? $refs.customerForm.update(chatProps.contact.customer.id) : $refs.customerForm.show()">
                <div class="d-flex flex-column pa-1">
                  <span class="text-subtitle-2 primary--text mb-1">{{ chatProps.contact.customer ? 'Ubah Data Pelanggan' : 'Buat baru' }}</span>
                  <span class="text-caption text--disabled">{{ chatProps.contact.customer ? 'Ubah data pelanggan yang terhubung' : 'Buat data pelanggan baru berdasarkan informasi profil chat' }}</span>
                </div>
              </v-list-item>
            </v-list>
          </v-tab-item>
          <v-tab-item>
            <v-card
              width="260px"
              class="pa-3 d-flex flex-column"
            >
              <div
                class="d-flex align-center cursor-pointer mb-2"
                @click="tabCustomer = 0"
              >
                <v-icon
                  size="16"
                  class="primary--text mr-2"
                >
                  {{ icons.mdiArrowLeft }}
                </v-icon>
                <span class="text-caption primary--text">
                  Kembali
                </span>
              </div>

              <span class="text-subtitle-2 mb-2">Hubungkan Pelanggan</span>
              <CustomerAutoSuggest
                v-model="customerData"
              />
              <v-btn
                color="primary"
                block
                small
                rounded
                :loading="loading"
                class="mt-2"
                @click="connectCustomer(customerData.id, chatProps.contact.id)"
              >
                Simpan
              </v-btn>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-menu>
    </div>

    <CustomerForm
      ref="customerForm"
      @afterSubmit="(id) => connectCustomer(id, chatProps.contact.id)"
      @success="$emit('success')"
    />
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { mdiArrowLeft } from '@mdi/js'
import CustomerAutoSuggest from '@/components/inputs/CustomerAutoSuggest.vue'
import CustomerForm from '@/views/customer/CustomerForm.vue'
import useCustomer from '@/composables/useCustomer'
import Vue from 'vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    CustomerAutoSuggest,
    CustomerForm,
  },
  props: {
    chatData: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  setup(props, { emit }) {
    const { connectProfileCustomer } = useCustomer()
    const customerData = ref()
    const chatProps = computed(() => props.chatData)
    const loading = ref(false)
    const tabCustomer = ref(0)
    const connectCustomer = (id, profile) => {
      loading.value = true
      connectProfileCustomer(id, profile).then(() => {
        loading.value = false
        emit('success')
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengoneksikan pelanggan!',
        })
      }).catch(() => {
        loading.value = false
      })
    }

    return {
      connectCustomer,
      customerData,
      tabCustomer,
      chatProps,
      loading,
      avatarText,
      icons: {
        mdiArrowLeft,
      },
    }
  },
}
</script>

<style>

</style>
