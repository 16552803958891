<template>
  <v-card>
    <v-card-text>
      <draggable
        :list="formData.customAttributes"
        :animation="200"
        ghost-class="ghost-card"
        group="value"
        :delay="500"
        :delay-on-touch-only="true"
      >
        <div
          v-for="(column, index) in formData.customAttributes"
          :key="index"
          class="d-flex mt-1 py-2 px-2 align-items-start"
        >
          <v-icon
            size="22"
            class="mr-2 ms-1 text--disabled mt-2"
          >
            {{ icons.mdiDrag }}
          </v-icon>
          <div class="custom-attribute-name">
            <v-text-field
              v-model="column.name"
              type="text"
              outlined
              label="Nama Atribut (Wajib Diisi)"
              :rules="[required]"
              :value="column.name"
              hide-details="auto"
              dense
            />
          </div>
          <div class="ml-3">
            <v-text-field
              type="text"
              outlined
              disabled
              label="Tipe Atribut"
              :value="column.data_type.name"
              hide-details="auto"
              dense
            />
          </div>
          <v-btn
            v-if="column.data_type.id === 4 || column.data_type.id === 5"
            elevation="0"
            icon
            class="d-flex ml-2 rounded-lg align-self-center"
            outlined
            color="warning"
            @click="$refs.customAttributeForm.edit(column, index)"
          >
            <v-icon
              size="24"
              color="warning"
            >
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
          <v-btn
            elevation="0"
            icon
            class="d-flex ml-2 rounded-lg align-self-center"
            outlined
            color="error"
            @click="confirmDelete(column.id, index)"
          >
            <v-icon
              size="24"
              color="error"
            >
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </div>
      </draggable>
      <div>
        <v-btn
          class="ma-4"
          outlined
          color="primary"
          @click="$refs.customAttributeForm.open()"
        >
          Tambah atribut baru
        </v-btn>
      </div>
    </v-card-text>
    <custom-attribute-form
      ref="customAttributeForm"
      :object-type-id="2"
      :custom-attributes="formData.customAttributes"
      :return-object="true"
      @update="$emit('update')"
    />
    <custom-attribute-confirmation
      ref="customAttributeConfirmation"
      :object-type-id="2"
      @success="submitUpdate()"
    />
  </v-card>
</template>
<script>
import { computed, ref } from '@vue/composition-api'
import {
  mdiDrag, mdiPencil, mdiTrashCan,
} from '@mdi/js'
import { required } from '@core/utils/validation'
import draggable from 'vuedraggable'
import useCustomAttribute from '@/composables/useCustomAttributes'
import CustomAttributeForm from '@/views/custom-attributes/CustomAttributeForm.vue'
import Vue from 'vue'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import { confirmUpdateDeleteCustomAttribute, updateJobTypeAttribute } from '@/graphql/mutations'
import store from '@/store'
import { queryDeleteCustomAttribute } from '@/graphql/queries'
import CustomAttributeConfirmation from '../../custom-attributes/CustomAttributeConfirmation.vue'

export default {
  components: {
    draggable,
    CustomAttributeForm,
    CustomAttributeConfirmation,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    mode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const formData = computed(() => props.data)
    const updateData = ref(null)
    const deleteList = ref([])
    const {
      loadingFetch, fetchCustomAttributes,
      removeCustomAttribute,
      loadingEditTypes,
    } = useCustomAttribute({ objectTypeId: 2 })
    const customAttributeConfirmation = ref(null)

    const confirmDelete = (id, index) => {
      Vue.$dialog({
        title: 'Hapus atribut?',
        body: 'Konfirmasi jika anda ingin menghapus atribut ini.',
      }).then(confirm => {
        if (confirm) {
          if (id) {
            deleteList.value.push(id)
          }

          formData.value.customAttributes.splice(index, 1)
        }
      })
    }

    const checkDeleteCustomAttribute = async () => {
      if (deleteList.value.length) {
        return apolloClient.query({
          query: queryDeleteCustomAttribute,
          fetchPolicy: 'no-cache',
          variables: {
            delete_id: deleteList.value,
            object_type_id: 2,
          },
        }).then(async result => {
          if (result.data.queryDeleteCustomAttribute.length > 0) {
            customAttributeConfirmation.value.show(result.data.queryDeleteCustomAttribute, 'Atribut', 'job')

            return Promise.resolve(false)
          }

          return Promise.resolve(true)
        }).catch(err => {
          errorHandling(err)
        })
      }

      return Promise.resolve(true)
    }

    const reset = () => {
      deleteList.value = []
    }

    const submitUpdate = () => {
      apolloClient.mutate({
        mutation: updateJobTypeAttribute,
        variables: {
          name: formData.value.name,
          jobtype_id: formData.value.id,
          color: formData.value.color,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(async result => {
        await apolloClient.mutate({
          mutation: confirmUpdateDeleteCustomAttribute,
          variables: {
            workspace_id: store.getters.getCurrentWorkspaceId,
            delete_id: deleteList.value,
            job_type_id: formData.value.id,
            object_type_id: 2,
            update: updateData.value,
          },
        })

        emit('success', result)
        Vue.notify({
          title: 'Sukses',
          text: 'Berhasil mengubah status!',
        })
      }).catch(err => {
        errorHandling(err)
      })
    }

    const submit = async data => {
      updateData.value = data
      if (await checkDeleteCustomAttribute()) {
        submitUpdate()
      }
    }

    return {
      loadingFetch,
      loadingEditTypes,
      formData,
      customAttributeConfirmation,

      fetchCustomAttributes,
      removeCustomAttribute,
      confirmDelete,
      submitUpdate,
      required,
      submit,
      reset,

      props,
      icons: {
        mdiDrag,
        mdiPencil,
        mdiTrashCan,
      },
    }
  },
}
</script>
<style>
  .custom-attribute-name {
    width: 300px;
    min-width: 125px;
  }
</style>
