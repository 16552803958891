<template>
  <v-card>
    <v-card-text>
      <!-- Active Status -->
      <div class="d-flex align-center justify-space-between mb-2">
        <span class="text-subtitle-1 d-block">Aktif</span>
        <div>
          <v-btn
            v-if="updateMode ? $can('update','JobStatus') : $can('create','JobStatus')"
            outlined
            color="primary"
            small
            @click="addStatus('Aktif')"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </div>
      </div>
      <draggable
        :list="formData.status.aktif"
        :animation="200"
        ghost-class="ghost-card"
        group="status"
        handle=".handle"
        :move="handleMove"
      >
        <v-card
          v-for="(status, index) in formData.status.aktif"
          :key="index"
          outlined
          class="py-6 px-2 mb-2"
        >
          <v-row :class="index === 0 ? '' : 'handle'">
            <v-col
              cols="12"
              sm="6"
              class="d-flex flex-row-reverse flex-sm-row"
            >
              <v-icon
                v-if="index !== 0"
                class="ml-2 cursor-move ml-sm-0"
              >
                {{ icons.mdiDrag }}
              </v-icon>
              <v-text-field
                v-model="status.name"
                label="Nama Status (wajib diisi)"
                placeholder="Input nama status job"
                dense
                hide-details="auto"
                class="ml-2"
                :rules="[required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <div class="d-flex">
                <color-picker
                  v-model="status.color"
                  label="Warna Status (wajib diisi)"
                  class="ml-2 ml-sm-0"
                  :rules="[required]"
                />
                <div v-if="index !== 0">
                  <v-icon
                    v-if="updateMode ? $can('update','JobStatus') : $can('create','JobStatus')"
                    class="ml-2"
                    @click="removeStatus(status.type, index)"
                  >
                    {{ icons.mdiCloseCircleOutline }}
                  </v-icon>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </draggable>
      <!-- Inactive Status -->
      <div class="d-flex align-center justify-space-between mb-2 pt-5">
        <span class="text-subtitle-1 d-block">Nonaktif</span>
        <div>
          <v-btn
            v-if="updateMode ? $can('update','JobStatus') : $can('create','JobStatus')"
            outlined
            color="primary"
            small
            @click="addStatus('Nonaktif')"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </div>
      </div>
      <draggable
        :list="formData.status.nonaktif"
        :animation="200"
        ghost-class="ghost-card"
        group="status"
      >
        <v-card
          v-for="(status, index) in formData.status.nonaktif"
          :key="index"
          outlined
          class="py-6 px-2 mb-2"
        >
          <v-row>
            <v-col
              cols="12"
              sm="6"
              class="d-flex flex-row-reverse flex-sm-row"
            >
              <v-icon
                class="ml-2 cursor-move ml-sm-0"
              >
                {{ icons.mdiDrag }}
              </v-icon>
              <v-text-field
                v-model="status.name"
                label="Nama Status (wajib diisi)"
                placeholder="Input nama status job"
                dense
                hide-details="auto"
                class="ml-2"
                :rules="[required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <div class="d-flex">
                <color-picker
                  v-model="status.color"
                  label="Warna Status (wajib diisi)"
                  class="ml-2 ml-sm-0"
                  :rules="[required]"
                />
                <v-icon
                  v-if="updateMode ? $can('update','JobStatus') : $can('create','JobStatus')"
                  class="ml-2"
                  @click="removeStatus(status.type, index)"
                >
                  {{ icons.mdiCloseCircleOutline }}
                </v-icon>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </draggable>
      <!-- Closed Status -->
      <div class="d-flex align-center justify-space-between pt-5 mb-2">
        <span class="text-subtitle-1 d-block">Tutup</span>
      </div>
      <v-card
        v-for="(status, index) in formData.status.tutup"
        :key="index"
        outlined
        class="py-6 px-2 mb-5"
      >
        <v-row>
          <v-col
            cols="12"
            sm="6"
            class="d-flex flex-row-reverse flex-sm-row"
          >
            <v-text-field
              v-model="status.name"
              label="Nama Status (wajib diisi)"
              placeholder="Input nama status job"
              dense
              hide-details="auto"
              class="ml-2"
              :rules="[required]"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <div class="d-flex">
              <color-picker
                v-model="status.color"
                label="Warna Status (wajib diisi)"
                class="ml-2 ml-sm-0"
                :rules="[required]"
              />
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-card-text>
    <attribute-delete-confirmation
      ref="attributeDeleteConfirmation"
      @success="updateStatus()"
    />
  </v-card>
</template>
<script>
import { required } from '@/@core/utils/validation'
import ColorPicker from '@/components/inputs/ColorPicker.vue'
import {
  addJobStatus,
  deleteJobStatus,
  updateJobTypeAttribute,
  updateJobStatus,
  updateJobStatusUrutan,
} from '@/graphql/mutations'
import store from '@/store'
import { randomizeColor } from '@/utils/colorSelection'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import {
  mdiCloseCircleOutline,
  mdiDrag,
  mdiPlus,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import Vue from 'vue'
import draggable from 'vuedraggable'
import AttributeDeleteConfirmation from './AttributeDeleteConfirmation.vue'

export default {
  components: {
    AttributeDeleteConfirmation,
    ColorPicker,
    draggable,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    mode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const attributeDeleteConfirmation = ref(null)
    const updateMode = computed(() => props.mode)
    const formData = computed(() => props.data)
    const deleteStatusList = ref([])
    const jobStatusUpdate = ref([])
    const jobStatusAdd = ref([])

    const removeStatus = (type, index) => {
      Vue.$dialog({
        title: 'Hapus Status?',
        body: 'Yakin ingin hapus status dari job type ini?',
      }).then(confirm => {
        if (confirm) {
          const status = formData.value.status[type.toLowerCase()][index]

          if (status.id) {
            deleteStatusList.value.push(status.id)
          }

          formData.value.status[type.toLowerCase()].splice(index, 1)
        }
      })
    }

    const reset = () => {
      deleteStatusList.value = []
    }

    const checkDeleteStatus = async () => {
      if (deleteStatusList.value.length) {
        return apolloClient.mutate({
          mutation: deleteJobStatus,
          variables: {
            delete_id: deleteStatusList.value,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        }).then(data => {
          if (data.data.deleteJobStatus.status === 'warning') {
            attributeDeleteConfirmation.value.show(JSON.parse(data.data.deleteJobStatus.data).job, jobStatusUpdate.value, 'Status')
            throw new Error(data.data.deleteJobStatus.msg)
          } else {
            return Promise.resolve(true)
          }
        }).catch(err => {
          Vue.notify({
            title: 'Warning',
            text: err.message,
            type: 'warn',
          })

          return Promise.resolve(false)
        })
      }

      return Promise.resolve(true)
    }

    const updateStatus = () => {
      apolloClient.mutate({
        mutation: updateJobTypeAttribute,
        variables: {
          name: formData.value.name,
          jobtype_id: formData.value.id,
          color: formData.value.color,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(async result => {
        apolloClient.mutate({
          mutation: updateJobStatus,
          variables: {
            workspace_id: store.getters.getCurrentWorkspaceId,
            job_status: jobStatusUpdate.value.map(el => ({
              id: el.id,
              name: el.name,
              color: el.color,
              next_job_status_id: el.job_order.map(or => or.next_job_status_id),
              type: el.type,
            })),
          },
        })

        if (jobStatusAdd.value.length) {
          apolloClient.mutate({
            mutation: addJobStatus,
            variables: {
              workspace_id: store.getters.getCurrentWorkspaceId,
              job_type: formData.value.id,
              job_status: jobStatusAdd.value.map(el => ({
                urutan: el.urutan,
                name: el.name,
                color: el.color,
                next_job_status_id: [],
                type: el.type,
              })),
            },
          })
        }

        await apolloClient.mutate({
          mutation: updateJobStatusUrutan,
          variables: {
            params: jobStatusUpdate.value.map(el => ({ id: el.id, urutan: el.urutan })),
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        })

        emit('success', result)
        Vue.notify({
          title: 'Sukses',
          text: 'Berhasil mengubah status!',
        })
      }).catch(err => {
        errorHandling(err)
      })
    }

    const submit = async jobStatusData => {
      jobStatusUpdate.value = jobStatusData.filter(el => el.id)
      jobStatusAdd.value = jobStatusData.filter(el => !el.id)

      if (await checkDeleteStatus()) {
        updateStatus()
      }
    }

    const addStatus = type => {
      formData.value.status[type.toLowerCase()].push({
        name: '',
        color: randomizeColor().hex,
        type,
      })
    }

    // prevent drag to first position on status type 'Aktif'
    const handleMove = event => event.draggedContext.futureIndex !== 0 || event.willInsertAfter

    return {
      attributeDeleteConfirmation,
      updateMode,
      formData,

      updateStatus,
      removeStatus,
      handleMove,
      addStatus,
      required,
      submit,
      reset,

      icons: {
        mdiCloseCircleOutline,
        mdiPlus,
        mdiDrag,
      },
    }
  },
}
</script>
