<template>
  <v-card>
    <v-card-text>
      <draggable
        :list="formData.priority"
        :animation="200"
        ghost-class="ghost-card"
        group="priority"
      >
        <v-card
          v-for="(priority, index) in formData.priority"
          :key="index"
          outlined
          class="py-6 px-2 mb-2"
        >
          <v-row>
            <v-col
              cols="12"
              sm="6"
              class="d-flex flex-row-reverse flex-sm-row"
            >
              <v-icon
                class="ml-2 cursor-move ml-sm-0"
              >
                {{ icons.mdiDrag }}
              </v-icon>
              <v-text-field
                v-model="priority.name"
                label="Nama Prioritas (wajib diisi)"
                placeholder="Input nama prioritas job"
                dense
                hide-details="auto"
                class="ml-2"
                :rules="[required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <div class="d-flex">
                <color-picker
                  v-model="priority.color"
                  label="Warna Priority (wajib diisi)"
                  class="ml-2 ml-sm-0"
                  :rules="[required]"
                />
                <div v-if="formData.priority.length > 1">
                  <v-icon
                    v-if="updateMode ? $can('update','JobStatus') : $can('create','JobStatus')"
                    class="ml-2"
                    @click="removePriority(index)"
                  >
                    {{ icons.mdiCloseCircleOutline }}
                  </v-icon>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </draggable>
      <v-btn
        v-if="updateMode ? $can('update','JobStatus') : $can('create','JobStatus')"
        block
        outlined
        color="primary"
        @click="addPriority"
      >
        <v-icon>
          {{ icons.mdiPlus }}
        </v-icon>
      </v-btn>
    </v-card-text>
    <attribute-delete-confirmation
      ref="attributeDeleteConfirmation"
      @success="updatePriority()"
    />
  </v-card>
</template>
<script>
import { required } from '@/@core/utils/validation'
import ColorPicker from '@/components/inputs/ColorPicker.vue'
import {
  addJobPriority,
  deleteJobPriority,
  updateJobTypeAttribute,
  updateJobPriority,
  updateJobPriorityUrutan,
} from '@/graphql/mutations'
import store from '@/store'
import { randomizeColor } from '@/utils/colorSelection'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import {
  mdiCloseCircleOutline,
  mdiDrag,
  mdiPlus,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import Vue from 'vue'
import draggable from 'vuedraggable'
import AttributeDeleteConfirmation from './AttributeDeleteConfirmation.vue'

export default {
  components: {
    ColorPicker,
    draggable,
    AttributeDeleteConfirmation,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    mode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const attributeDeleteConfirmation = ref(null)
    const updateMode = computed(() => props.mode)
    const formData = computed(() => props.data)
    const deletePriorityList = ref([])
    const jobPriorityUpdate = ref([])
    const jobPriorityAdd = ref([])

    const removePriority = index => {
      Vue.$dialog({
        title: 'Hapus Priority?',
        body: 'Yakin ingin hapus priority dari job type ini?',
      }).then(confirm => {
        const priority = formData.value.priority[index]

        if (priority.id) {
          deletePriorityList.value.push(priority.id)
        }

        if (confirm) {
          formData.value.priority.splice(index, 1)
        }
      })
    }

    const reset = () => {
      deletePriorityList.value = []
    }

    const checkDeletePriority = async () => {
      if (deletePriorityList.value.length) {
        return apolloClient.mutate({
          mutation: deleteJobPriority,
          variables: {
            delete_id: deletePriorityList.value,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        }).then(data => {
          if (data.data.deleteJobPriority.status === 'warning') {
            attributeDeleteConfirmation.value.show(JSON.parse(data.data.deleteJobPriority.data).job, jobPriorityUpdate.value, 'Prioritas')
            throw new Error(data.data.deleteJobPriority.msg)
          } else {
            return Promise.resolve(true)
          }
        }).catch(err => {
          Vue.notify({
            title: 'Warning',
            text: err.message,
            type: 'warn',
          })

          return Promise.resolve(false)
        })
      }

      return Promise.resolve(true)
    }

    const updatePriority = () => {
      apolloClient.mutate({
        mutation: updateJobTypeAttribute,
        variables: {
          name: formData.value.name,
          jobtype_id: formData.value.id,
          color: formData.value.color,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(async result => {
        apolloClient.mutate({
          mutation: updateJobPriority,
          variables: {
            workspace_id: store.getters.getCurrentWorkspaceId,
            job_priority: jobPriorityUpdate.value.map(el => ({
              id: el.id,
              name: el.name,
              color: el.color,
            })),
          },
        })

        if (jobPriorityAdd.value.length) {
          apolloClient.mutate({
            mutation: addJobPriority,
            variables: {
              workspace_id: store.getters.getCurrentWorkspaceId,
              job_type: formData.value.id,
              job_priority: jobPriorityAdd.value.map(el => ({
                urutan: el.urutan,
                name: el.name,
                color: el.color,
              })),
            },
          })
        }

        await apolloClient.mutate({
          mutation: updateJobPriorityUrutan,
          variables: {
            params: jobPriorityUpdate.value.map(el => ({ id: el.id, urutan: el.urutan })),
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        })

        emit('success', result)
        Vue.notify({
          title: 'Sukses',
          text: 'Berhasil mengubah prioritas!',
        })
      }).catch(err => {
        errorHandling(err)
      })
    }

    const submit = async jobPriorityData => {
      jobPriorityUpdate.value = jobPriorityData.filter(el => el.id)
      jobPriorityAdd.value = jobPriorityData.filter(el => !el.id)

      if (await checkDeletePriority()) {
        updatePriority()
      }
    }

    const addPriority = () => {
      formData.value.priority.push({
        name: '',
        color: randomizeColor().hex,
      })
    }

    return {
      attributeDeleteConfirmation,
      deletePriorityList,
      updateMode,
      formData,

      updatePriority,
      removePriority,
      addPriority,
      required,
      submit,
      reset,

      icons: {
        mdiCloseCircleOutline,
        mdiPlus,
        mdiDrag,
      },
    }
  },
}
</script>
