<template>
  <div>
    <v-dialog
      v-model="isOpen"
      max-width="800"
      scrollable
      @input="!$event ? reset() : null"
    >
      <v-card>
        <div class="d-flex justify-space-between pa-4">
          <v-row>
            <v-col cols="2">
              <span class="text-h6 font-medium primary--text">{{ updateMode ? 'Ubah Job' : 'Buat Job' }}</span>
            </v-col>
            <v-col
              cols="5"
            >
              <treeselect
                v-if="!loadingDetail"
                v-model="jobType"
                :options="folderList"
                :multiple="false"
                :disable-branch-nodes="true"
                :normalizer="normalizer"
                :clearable="false"
                :required="true"
                placeholder="Pilih job type"
                :disabled="updateMode"
              />
            </v-col>
          </v-row>
          <v-icon
            @click="close()"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </div>
        <v-card-text class="job-form-content">
          <v-form
            v-if="!loadingDetail"
            ref="jobForm"
            lazy-validation
            @submit.prevent="submitForm"
          >
            <v-tabs
              v-model="activeTab"
              grow
              flat
              style="box-shadow: none !important;"
            >
              <v-tab>
                Detail
              </v-tab>
              <v-tab>
                Produk
              </v-tab>
              <v-tab-item
                class="pt-5"
                eager
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="formData.name"
                      :rules="[required]"
                      label="Nama (wajib diisi)"
                      placeholder="Input nama"
                      outlined
                      dense
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <div class="d-flex">
                      <date-range-picker
                        :value="{
                          startDate: formData.start_date,
                          endDate: formData.expected_close_date,
                        }"
                        :rules="[required]"
                        @update="setDateRange($event)"
                        @select="!formData.expected_close_date && !formData.start_date ? setDateRange($event) : null"
                      />
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="setDateRange({ startDate: null, endDate: null })"
                          >
                            <v-icon>
                              {{ icons.mdiClose }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Kosongkan tanggal mulai & tanggal akhir</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <ckeditor
                      v-model="formData.description"
                      :editor="editor"
                      :config="editorConfig"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <customer-auto-suggest
                      v-model="formData.customer_id"
                      :return-object="false"
                      :attach="true"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="formData.job_priority_id"
                      :items="priorityOptions"
                      label="Prioritas"
                      outlined
                      clearable
                      item-text="name"
                      item-value="id"
                      dense
                      hide-details="auto"
                      :disabled="!updateMode && !jobType"
                    >
                      <template #item="{ item }">
                        <v-chip
                          small
                          :color="item.color"
                        >
                          {{ item.name }}
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="formData.job_status_id"
                      :items="statusOptions"
                      label="Status Job (wajib diisi)"
                      outlined
                      item-text="name"
                      item-value="id"
                      dense
                      hide-details="auto"
                      :rules="[required]"
                      :disabled="!jobType"
                    >
                      <template #item="{ item }">
                        <v-chip
                          small
                          :color="item.color"
                        >
                          {{ item.name }}
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <UserTeamAutoSuggest
                      v-if="!loadingDetail"
                      v-model="formData.assignTo"
                      label="Ditugaskan ke"
                      :job-type-id="jobType"
                      :attach="true"
                      show-teams
                      multiple
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-card
                      outlined
                      class="pa-3 mb-4 d-flex flex-column"
                    >
                      <span class="text-subtitle-2 font-medium mb-3">Atribut Lainnya</span>
                      <PerfectScrollbar
                        v-if="updateMode || !updateMode && jobType"
                        class="custom-attribute-scroll-bar"
                      >
                        <div
                          v-for="(attribute, index) in customAttributesArray"
                          :key="attribute.custom_attribute.id"
                          class="mb-2"
                        >
                          <v-text-field
                            v-if="attribute.custom_attribute.data_type.id === 1 || attribute.custom_attribute.data_type.id === 2"
                            v-model="customAttributesArray[index].value"
                            :type="attribute.custom_attribute.data_type.id === 1 ? 'text' : 'number'"
                            :label="attribute.custom_attribute.name"
                            :placeholder="'Input ' + attribute.custom_attribute.name + ' job'"
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-2"
                          />
                          <div class="mb-2">
                            <DateTimePicker
                              v-if="attribute.custom_attribute.data_type.id === 3"
                              v-model="customAttributesArray[index].value"
                              :label="attribute.custom_attribute.name"
                              :placeholder="attribute.custom_attribute.name"
                            />
                          </div>
                          <v-autocomplete
                            v-if="attribute.custom_attribute.data_type.id === 4 || attribute.custom_attribute.data_type.id === 5"
                            v-model="customAttributesArray[index].value"
                            :items="attribute.custom_attribute.options"
                            :label="attribute.custom_attribute.name"
                            :placeholder="attribute.custom_attribute.name"
                            outlined
                            dense
                            clearable
                            hide-details="auto"
                            :multiple="attribute.custom_attribute.data_type.id === 5"
                            :small-chips="attribute.custom_attribute.data_type.id === 5"
                            item-text="name"
                            item-value="id"
                            class="mb-2"
                          />
                        </div>
                      </PerfectScrollbar>
                      <v-btn
                        text
                        elevation="0"
                        block
                        outlined
                        color="primary"
                        :disabled="!updateMode && !jobType"
                        @click="updateMode ? $refs.customAttributesList.open() : $refs.customAttributeForm.open()"
                      >
                        <v-icon>
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </v-btn>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item eager>
                <ProductAutoSuggest
                  class="mt-4"
                  :value="product"
                  :emit-mode="true"
                  :multiple="true"
                  @input="productInput"
                />
                <PerfectScrollbar
                  v-if="product.length"
                  :key="productKey"
                  class="product-scroll-bar mt-4 pr-4"
                >
                  <v-card
                    v-for="data in product"
                    :key="data.id"
                    class="mb-4"
                    outlined
                  >
                    <v-card-text>
                      <div class="d-flex">
                        <div class="d-flex flex-column w-full">
                          <div class="d-flex justify-space-between w-full ">
                            <router-link
                              target="_blank"
                              :to="{ name: 'product-detail', params: { id: data.id} }"
                            >
                              <p class="body-2 font-weight-bold primary--text">
                                {{ data.name }}
                              </p>
                            </router-link>
                            <v-chip
                              small
                              color="warning"
                              class=" ml-4 warning--text font-weight-bold v-chip-light-bg"
                            >
                              Shopee
                            </v-chip>
                          </div>
                          <v-row dense>
                            <v-col
                              cols="12"
                              sm="6"
                            >
                              <p class="mb-1 caption font-weight-bold">
                                Kode SKU
                              </p>
                              <p
                                class="caption"
                              >
                                {{ data.code_sku ? data.code_sku : "Tidak ada kode SKU" }}
                              </p>
                              <p class="mb-1 caption font-weight-bold">
                                Harga
                              </p>
                              <p
                                class="caption"
                              >
                                {{ data.price ? data.price : 0 }}
                              </p>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              class="d-flex flex-column align-end justify-end"
                            >
                              <p class="mb-1 caption font-weight-bold">
                                Kuantitas
                              </p>
                              <Counter
                                :id="data.id"
                                :qty="data.quantity ? data.quantity:1"
                                @changeQty="changeQuantity"
                              />
                              <v-icon
                                color="error"
                                @click="removeProduct(data.id)"
                              >
                                {{ icons.mdiTrashCanOutline }}
                              </v-icon>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </PerfectScrollbar>
                <div
                  v-else
                  class="h-full d-flex align-center justify-center"
                >
                  <p class="font-weight-semibold pb-3 mt-5">
                    Pilih produk terlebih dahulu
                  </p>
                </div>
              </v-tab-item>
            </v-tabs>

            <div class="d-flex flex-column flex-md-row mt-4">
              <v-btn
                class="mb-2 mb-md-0 mr-md-2 ml-md-auto"
                color="primary"
                :loading="loadingSubmit"
                @click="submitForm()"
              >
                Simpan Job
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
      <v-overlay
        opacity="0.2"
        :value="loadingDetail"
      >
        <v-progress-circular
          indeterminate
          size="32"
        />
      </v-overlay>
    </v-dialog>

    <JobTypeForm
      ref="typeForm"
      @success="fetchJobType"
    />
    <custom-attribute-form
      ref="customAttributeForm"
      :object-type-id="2"
      @update="$emit('success'); close()"
    />
    <custom-attributes-list
      v-if="formData.id"
      ref="customAttributesList"
      :object-id="formData.id"
      :object-type-id="2"
      :job-type-id="formData.job_type_id"
      @update="$emit('success'); close()"
    />
  </div>
</template>

<script>
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import { integerValidator, required } from '@core/utils/validation'
import {
  mdiClose,
  mdiDotsHorizontal,
  mdiPlus,
  mdiTrashCanOutline,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import { format, parseISO } from 'date-fns'
import Vue from 'vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { isNumber } from '@vueuse/shared'
import Counter from '@/components/inputs/Counter.vue'
import CustomerAutoSuggest from '@/components/inputs/CustomerAutoSuggest.vue'
import DateRangePicker from '@/components/inputs/DateRangePicker.vue'
import DateTimePicker from '@/components/inputs/DateTimePicker.vue'
import ProductAutoSuggest from '@/components/inputs/ProductAutoSuggest.vue'
import UserTeamAutoSuggest from '@/components/inputs/UserTeamAutoSuggest.vue'
import useCustomAttributes from '@/composables/useCustomAttributes'
import { addJob, assignObject, updateJob } from '@/graphql/mutations'
import {
  getFolders,
  jobDetail,
  jobPriority,
  jobStatus,
  jobTypes,
} from '@/graphql/queries'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import CustomAttributeForm from '@/views/custom-attributes/CustomAttributeForm.vue'
import CustomAttributesList from '@/views/custom-attributes/CustomAttributesList.vue'
import { apolloClient } from '@/vue-apollo'
import JobTypeForm from './JobTypeForm.vue'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment'
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import CodePlugin from '@ckeditor/ckeditor5-basic-styles/src/code'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import StrikethroughPlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import SubscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import SuperscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline'
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import FontPlugin from '@ckeditor/ckeditor5-font/src/font'
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
import HighlightPlugin from '@ckeditor/ckeditor5-highlight/src/highlight'
import HorizontalLinePlugin from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline'
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import IndentPlugin from '@ckeditor/ckeditor5-indent/src/indent'
import ListPlugin from '@ckeditor/ckeditor5-list/src/list'
import TodoListPlugin from '@ckeditor/ckeditor5-list/src/todolist'
import MediaEmbedPlugin from '@ckeditor/ckeditor5-media-embed/src/mediaembed'
import PageBreakPlugin from '@ckeditor/ckeditor5-page-break/src/pagebreak'
import PasteFromOfficePlugin from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice'
import RemoveFormatPlugin from '@ckeditor/ckeditor5-remove-format/src/removeformat'
import StandardEditingPlugin from '@ckeditor/ckeditor5-restricted-editing/src/standardeditingmode'
import SpecialCharactersPlugin from '@ckeditor/ckeditor5-special-characters/src/specialcharacters'
import TablePlugin from '@ckeditor/ckeditor5-table/src/table'
import UploadPlugin from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter'
import WordCountPlugin from '@ckeditor/ckeditor5-word-count/src/wordcount'
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials'

export default {
  components: {
    CustomerAutoSuggest,
    UserTeamAutoSuggest,
    JobTypeForm,
    CustomAttributesList,
    CustomAttributeForm,
    DateTimePicker,
    ProductAutoSuggest,
    DateRangePicker,
    Counter,
    PerfectScrollbar,
    Treeselect,
  },

  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          AlignmentPlugin,
          AutoformatPlugin,
          BlockQuotePlugin,
          FontPlugin,
          HeadingPlugin,
          HighlightPlugin,
          HorizontalLinePlugin,
          ImagePlugin,
          IndentPlugin,
          ListPlugin,
          MediaEmbedPlugin,
          PageBreakPlugin,
          PasteFromOfficePlugin,
          RemoveFormatPlugin,
          StandardEditingPlugin,
          SpecialCharactersPlugin,
          SpecialCharactersEssentials,
          TablePlugin,
          UploadPlugin,
          WordCountPlugin,
          StrikethroughPlugin,
          UnderlinePlugin,
          SubscriptPlugin,
          SuperscriptPlugin,
          CodePlugin,
          TodoListPlugin,
        ],
        toolbar: {
          items: [
            // /* 'findAndReplace', */ 'selectAll', '|',
            'heading', '|',
            'bold', 'italic', 'strikethrough', 'underline', 'code', 'subscript', 'superscript', 'removeFormat', '|',
            'alignment', 'bulletedList', 'numberedList', 'todoList', '|',
            'outdent', 'indent', '|',
            'undo', 'redo',
            '-',
            'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
            'link', /* 'insertImage', */ 'blockQuote', 'insertTable', 'mediaEmbed', '|',
            'specialCharacters', 'horizontalLine', 'pageBreak', '|',
            // 'textPartLanguage', '|',
            // 'sourceEditing',
          ],
          shouldNotGroupWhenFull: true,
        },
      },
    }
  },

  setup(props, { emit }) {
    const jobForm = ref()
    const isOpen = ref(false)
    const formData = ref({
      id: null,
      name: '',
      start_date: null,
      expected_close_date: null,
      description: '',
      job_type_id: null,
      job_status_id: null,
      job_priority_id: null,
      job_product: null,
      customer_id: null,
      assignTo: [],
    })
    const product = ref([])
    const productKey = ref(0)
    const loadingSubmit = ref(false)
    const loadingDetail = ref(false)
    const updateMode = ref(false)
    const showDate = ref(false)
    const maxDate = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
    const jobType = ref()

    const { customAttributesArray, fetchCustomAttributes } = useCustomAttributes({ objectTypeId: 2 })

    const types = ref([])
    const fetchJobType = () => {
      apolloClient.query({
        query: jobTypes,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        types.value = result.data.jobTypes
      })
    }

    const statusOptions = ref([])
    const priorityOptions = ref([])

    const fetchJobStatus = () => {
      apolloClient.query({
        query: jobStatus,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          job_type: [formData.value.job_type_id],
        },
      }).then(result => {
        statusOptions.value = result.data.jobStatus

        if (!updateMode.value) { formData.value.job_status_id = result.data.jobStatus[0].id }
      })
    }

    const fetchJobPriority = () => {
      apolloClient.query({
        query: jobPriority,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          job_type: [formData.value.job_type_id],
        },
      }).then(result => {
        priorityOptions.value = result.data.jobPriority
      })
    }

    watch(jobType, () => {
      formData.value.job_type_id = jobType.value

      if (isOpen.value && !updateMode.value) {
        fetchJobStatus()
        fetchJobPriority()
        fetchCustomAttributes(null, jobType.value)
      }
    })

    const reset = () => {
      updateMode.value = false
      loadingSubmit.value = false
      loadingDetail.value = false
      jobType.value = null

      // reset
      formData.value = {
        id: null,
        name: '',
        start_date: null,
        expected_close_date: null,
        description: '',
        job_type_id: null,
        job_status_id: null,
        job_priority_id: null,
        job_product: null,
        customer: null,
        assignTo: [],
      }

      jobForm.value.reset()
      customAttributesArray.value = null
    }

    const close = () => {
      isOpen.value = false
      reset()
    }

    const folderList = ref(null)
    const fetchFolders = () => {
      apolloClient.query({
        query: getFolders,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          filter: {
            show_archived: false,
            sort: 'name',
            sortType: 'ASC',
          },
        },
      }).then(result => {
        folderList.value = result.data.folders
      }).catch(err => {
        close()
        errorHandling(err)
      })
    }

    const show = jobTypeId => {
      if (jobTypeId) {
        jobType.value = jobTypeId
      }
      isOpen.value = true
    }

    const update = id => {
      isOpen.value = true
      loadingDetail.value = true
      apolloClient.query({
        query: jobDetail,
        fetchPolicy: 'no-cache',
        variables: {
          job_id: +id,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        const data = result.data.jobDetail
        formData.value = {
          id,
          name: data.name,
          start_date: data.start_date,
          expected_close_date: data.expected_close_date,
          description: data.description,
          job_status_id: data.status.id,
          job_type_id: data.status.job_type.id,
          job_priority_id: data.priority?.id,
          customer: data.customer,
          customer_id: data.customer?.id,
          job_product: data.products.map(el => ({
            product_id: el.product.id,
            quantity: el.quantity,
          })),
          assignTo: data.assignedTo.map(el => `user-${el.user.id}`),
        }
        product.value = data.products.map(el => ({
          ...el.product,
          quantity: el.quantity,
        }))
        fetchJobStatus()
        fetchJobPriority()
        fetchCustomAttributes(formData.value.id, formData.value.job_type_id)
        updateMode.value = true
        jobType.value = data.status.job_type.id
        loadingDetail.value = false
      }).catch(err => {
        errorHandling(err)
        close()
      })
    }

    const changeQuantity = ({ id, qty }) => {
      const target = formData.value.job_product.find(val => val.product_id === id)
      target.quantity = qty
      const data = formData.value.job_product.filter(f => f.product_id !== id)
      formData.value.job_product = [...data, target]
    }

    const removeProduct = id => {
      product.value = product.value.filter(val => val.id !== id)
      formData.value.job_product = formData.value.job_product.filter(val => val.product_id !== id)
    }

    const productInput = (data, input) => {
      product.value = data
      formData.value.job_product = data.length ? data.map(val => ({ product_id: val.id, quantity: !input ? 1 : val.quantity })) : null
    }

    const submitForm = async () => {
      if (jobForm.value.validate()) {
        const teams = []
        const users = []

        formData.value.assignTo = formData.value.assignTo.map(el => el.id ?? el)

        formData.value.assignTo
        && formData.value.assignTo.length
        && formData.value.assignTo.map(value => {
          if (!isNumber(value)) {
            const ids = value.split('-')
            if (ids[0] === 'team') teams.push(+ids[1])
            if (ids[0] === 'user') users.push(+ids[1])
          }
        })
        loadingSubmit.value = true
        const payloadAttr = customAttributesArray.value.map(item => {
          let val
          if (item.custom_attribute.data_type.id === 3) val = item.value ? new Date(item.value) : null
          else if (item.custom_attribute.data_type.id === 4) val = item.value?.toString() || null
          else if (item.custom_attribute.data_type.id === 5) val = item.value && item.value.length ? JSON.stringify(item.value.sort((a, b) => a - b)) : null
          else val = item.value || null

          return {
            id: item.custom_attribute.id,
            value: val,
          }
        })

        if (!updateMode.value) {
          apolloClient
            .mutate({
              mutation: addJob,
              variables: {
                ...formData.value,
                start_date: new Date(Date.parse(formData.value.start_date)),
                expected_close_date: new Date(Date.parse(formData.value.expected_close_date)),
                assign_user_id: users.length ? users : null,
                assign_team_id: teams.length ? teams : null,
                workspace_id: store.getters.getCurrentWorkspaceId,
                custom_attribute: payloadAttr,
              },
            })
            .then(async () => {
              loadingSubmit.value = false
              emit('success')
              Vue.notify({
                title: 'Sukses!',
                text: 'Berhasil membuat job!',
              })
              close()
            })
            .catch(err => {
              errorHandling(err)
              loadingSubmit.value = false
            })
        } else {
          apolloClient.mutate({
            mutation: updateJob,
            variables: {
              ...formData.value,
              start_date: new Date(Date.parse(formData.value.start_date)),
              expected_close_date: new Date(Date.parse(formData.value.expected_close_date)),
              workspace_id: store.getters.getCurrentWorkspaceId,
              custom_attribute: payloadAttr,
            },
          }).then(async () => {
            loadingSubmit.value = false
            await apolloClient.mutate({
              mutation: assignObject,
              variables: {
                assign_user_id: users.length ? users : null,
                assign_team_id: teams.length ? teams : null,
                job_id: formData.value.id,
              },
            }).then(() => {
              emit('success')
              Vue.notify({
                title: 'Sukses!',
                text: 'Berhasil mengubah job!',
              })
              close()
            }).catch(err => {
              errorHandling(err)
            })
          }).catch(err => {
            loadingSubmit.value = false
            errorHandling(err)
          })
        }
      }
    }

    const setDateRange = event => {
      formData.value.start_date = event.startDate
      formData.value.expected_close_date = event.endDate
    }

    const normalizer = node => ({
      id: node.id,
      label: node.name,
      children: node.job_types,
    })

    onMounted(() => {
      fetchFolders()
    })

    const activeTab = ref(0)

    return {
      jobForm,
      isOpen,
      formData,
      loadingSubmit,
      loadingDetail,
      updateMode,
      showDate,
      maxDate,
      types,
      customAttributesArray,
      jobType,
      product,
      productKey,
      statusOptions,
      priorityOptions,
      activeTab,
      folderList,

      show,
      close,
      reset,
      update,
      submitForm,
      fetchJobType,
      required,
      integerValidator,
      productInput,
      changeQuantity,
      removeProduct,
      fetchCustomAttributes,
      setDateRange,
      normalizer,

      icons: {
        mdiClose,
        mdiDotsHorizontal,
        mdiPlus,
        mdiTrashCanOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.ps-job-form {
  max-height: 390px;
}
.job-form-content {
  @include style-scroll-bar();
  padding-right: 12px !important;
  overflow-x: hidden;
  overflow-y: scroll !important;
}
.ck-editor__editable_inline {
  max-height: 400px;
}
.custom-attribute-scroll-bar {
  padding-right: 12px !important;
  margin-right: -12px !important;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 250px;
}
.product-scroll-bar {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
}
</style>
