import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-2 mr-2 d-flex flex-column align-center",on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"size":"40"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.label))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }