<template>
  <div
    v-if="state.activeChat.value && state.activeChat.value.data"
    class="chat-information h-full"
  >
    <div v-if="!loading">
      <v-tabs-items
        v-model="detailTab"
        class="h-full"
        touchless
      >
        <v-tab-item>
          <div
            v-if="detailTab === 0"
            class="h-full d-flex flex-column"
          >
            <div class="pa-4 d-flex align-center justify-space-between">
              <v-icon
                class="d-inline-flex d-md-none"
                size="26px"
                @click="$emit('close-right-sidebar')"
              >
                {{ icons.mdiChevronDoubleRight }}
              </v-icon>
              <v-icon
                class="ml-auto"
                size="24px"
                @click="detailTab = 1"
              >
                {{ icons.mdiMagnify }}
              </v-icon>
            </div>
            <div class="scrollable-detail">
              <ConnectCustomer
                :chat-data="state.activeChat.value"
                @success="$emit('refetch')"
              />
              <AssignAgent
                :active-chat="state.activeChat.value"
                @success="$emit('refetch')"
              />
              <!-- <ChatProspect /> -->
              <div class="d-flex align-center mt-2">
                <span class="text-caption font-weight-bold ms-4 me-2">Detail Sesi</span>
                <v-divider class="my-4" />
              </div>
              <div class="px-4 d-flex flex-column">
                <div class="d-flex justify-space-between align-center">
                  <div class="d-flex flex-column">
                    <span class="text-caption text--disabled">Status</span>
                    <v-chip
                      color="primary"
                      class="text-capitalize"
                      small
                    >
                      {{ state.activeChat.value.data.status.name }}
                    </v-chip>
                  </div>
                </div>
              </div>
              <ChatObject
                :customer="state.activeChat.value.contact.customer ? state.activeChat.value.contact.customer : {}"
              />
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div
            v-if="detailTab === 1"
            class="h-full d-flex flex-column"
          >
            <div class="pa-4 d-flex flex-column">
              <div class="d-flex align-center">
                <v-icon
                  class="mr-2"
                  size="18px"
                  @click="detailTab = 0"
                >
                  {{ icons.mdiArrowLeft }}
                </v-icon>
                <p class="text-subtitle-2 ma-0">
                  Cari chat
                </p>
              </div>
              <v-text-field
                class="mt-4"
                placeholder="Cari di dalam chat ini"
                outlined
                dense
                hide-details
                clearable
                :loading="loadingSearch"
                @input="
                  loadingSearch = true
                  searchChat($event)
                "
              />
              <v-divider
                v-if="searchResult.length"
                class="my-4"
              />
            </div>
            <div class="scrollable-detail">
              <div
                v-for="result in searchResult"
                :key="result.id"
                @click="$emit('onSearchResultClick', result.id)"
              >
                <ChatCard :data="result" />
                <v-divider class="my-4" />
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div
      v-else
      class="d-flex align-center justify-center h-full"
    >
      <v-progress-circular
        indeterminate
        size="36"
        color="primary"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import {
  mdiMagnify, mdiArrowLeft, mdiChevronDoubleRight, mdiSquareEditOutline, mdiPlus,
} from '@mdi/js'
import { useDebounceFn } from '@vueuse/core'
import { createFieldMapper } from 'vuex-use-fields'
import { ellipsis, formatDate } from '@core/utils/filter'
import { apolloClient } from '@/vue-apollo'
import store from '@/store'
import { updateTelegramRoomStatus } from '@/graphql/mutations'
import ConnectCustomer from './components/ConnectCustomer.vue'
import ChatObject from './components/ChatObject.vue'
import AssignAgent from './components/AssignAgent.vue'
// import ChatProspect from './components/ChatProspect.vue'
import ChatCard from '@/views/chat/components/ChatCard.vue'

const useFieldChat = createFieldMapper({ getter: 'chat/getField', setter: 'chat/setField' })

export default {
  components: {
    ConnectCustomer,
    ChatObject,
    AssignAgent,
    // ChatProspect,
    ChatCard,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const state = {
      ...useFieldChat(['activeChat', 'statusRoomOptions']),
    }
    const searchQuery = ref('')
    const loadingSearch = ref(false)
    const detailTab = ref(0)
    const searchResult = ref([])
    const searchChat = useDebounceFn(data => {
      if (data && data.length >= 3) {
        loadingSearch.value = true
        apolloClient
          .query({
            variables: {
              pagination: {
                limit: 1000000,
                offset: 0,
              },
              room_id: state.activeChat.value.data.id,
              search: data,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
            fetchPolicy: 'no-cache',
          })
          .then(result => {
            searchResult.value = result.data.chatList
            loadingSearch.value = false
          })
      } else {
        searchResult.value = []
        loadingSearch.value = false
      }
    }, 1000)
    const statusRoom = computed(() => store.state['app-chat'].statusRoomOptions.filter(el => el.id !== props.state.activeChat.value.data.status.id))

    const loadingUpdateStatus = ref(false)
    const updateStatus = status => {
      loadingUpdateStatus.value = true
      apolloClient
        .mutate({
          mutation: updateTelegramRoomStatus,
          variables: {
            status_id: status.id,
            room_id: props.state.activeChat.value.data.id,
          },
        })
        .then(() => {
          emit('update-room-status', { roomId: props.state.activeChat.value.data.id, status })
          loadingUpdateStatus.value = false
        })
    }

    return {
      searchQuery,
      searchChat,
      loadingSearch,
      detailTab,
      searchResult,
      statusRoom,
      ellipsis,
      formatDate,
      updateStatus,
      loadingUpdateStatus,

      state,

      icons: {
        mdiMagnify,
        mdiArrowLeft,
        mdiChevronDoubleRight,
        mdiSquareEditOutline,
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.scrollable-detail {
  @include style-scroll-bar();
  position: relative;
  overflow-y: auto;
}
</style>
