<template>
  <div class="chat-left-sidebar h-full">
    <div class="top-fixed w-full">
      <div
        class="d-flex align-center"
        style="padding: 15px 16px"
      >
        <input-icon-toggle
          v-model="searchQuery"
          :icon="icons.mdiMagnify"
          placeholder="Cari chat atau pelanggan"
          class="me-auto"
          direction="right"
          icon-size="24px"
          @input="
            loadingSearch = true
            searchChat($event)
          "
          @close="currentTab = 0"
        />
        <v-menu
          v-model="showFilter"
          :close-on-content-click="false"
          max-width="290px"
          min-width="290px"
          offset-y
          left
          nudge-right="32"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="me-2"
              size="24px"
              v-bind="attrs"
              v-on="on"
            >
              {{ icons.mdiFilterVariant }}
            </v-icon>
          </template>

          <v-card class="pa-4">
            <span class="text-subtitle-1 font-weight-bold">Filter Chat</span>
            <v-divider class="my-4" />
            <v-autocomplete
              v-model="filterChat.agentId"
              label="Filter Agent"
              outlined
              :items="[]"
              dense
            />
            <v-autocomplete
              v-model="filterChat.custId"
              label="Filter Customer"
              outlined
              :items="[]"
              dense
            />
            <v-btn
              color="primary"
              block
            >
              Filter
            </v-btn>
          </v-card>
        </v-menu>
        <v-menu
          :close-on-content-click="false"
          bottom
          offset-y
          left
          max-width="260px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
            >
              {{ icons.mdiDotsVertical }}
            </v-icon>
          </template>
          <v-list nav>
            <v-list-item @click="$refs.chatCommand.show()">
              <div class="d-flex flex-column pa-1">
                <span class="text-subtitle-2 primary--text mb-1">Chat Bot Command</span>
                <span class="text-caption text--disabled">Atur command yang dapat dipakai pelanggan</span>
              </div>
            </v-list-item>
            <v-list-item @click="$refs.chatAutoReply.show()">
              <div class="d-flex flex-column pa-1">
                <span class="text-subtitle-2 primary--text mb-1">Chat Autoreply</span>
                <span class="text-caption text--disabled">Atur balasan pesan otomatis ketika pelanggan mengirimkan pesan di luar jam kerja.</span>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-icon
          class="d-inline-flex d-md-none ms-2"
          size="26px"
          @click="$emit('close-left-sidebar')"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-chip-group
        class="ms-3 mb-1"
        active-class="primary--text"
        column
        multiple
      >
        <v-chip
          small
          active
        >
          Ongoing
        </v-chip>
        <v-chip small>
          Request
        </v-chip>
        <v-chip small>
          Idle
        </v-chip>
      </v-chip-group>
      <v-divider />
    </div>
    <perfect-scrollbar
      :options="perfectScrollbarOptions"
      class="ps-chat-left-sidebar pb-3 px-3"
    >
      <v-tabs-items
        v-model="currentTab"
        class="h-full"
      >
        <v-tab-item>
          <div class="mt-2" />
          <div v-if="state.loadingChats.value">
            <v-skeleton-loader
              v-for="i in 4"
              :key="i"
              type="list-item-avatar-two-line"
              class="p-0"
            />
          </div>
          <chat-card
            v-for="chat in state.chatRoomList.value"
            v-else
            :key="`chat-${chat.id}`"
            :data="chat"
            :is-active="isChatActive(chat.profile.id)"
            :class="{ 'bg-gradient-primary active-chat-contact': isChatActive(chat.profile.id) }"
            @click="isChatActive(chat.profile.id) ? null : $emit('open-chat', chat)"
          />
        </v-tab-item>
      </v-tabs-items>
    </perfect-scrollbar>

    <chat-command
      ref="chatCommand"
    />

    <chat-auto-reply
      ref="chatAutoReply"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { createFieldMapper } from 'vuex-use-fields'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import {
  mdiMagnify, mdiFilterVariant, mdiClose, mdiDotsVertical,
} from '@mdi/js'
import { useDebounceFn } from '@vueuse/core'
import InputIconToggle from '@/components/inputs/InputIconToggle.vue'
import ChatCard from './components/ChatCard.vue'
import ChatCommand from './components/ChatCommand.vue'
import ChatAutoReply from './components/ChatAutoReply.vue'
import { apolloClient } from '@/vue-apollo'

const useFieldChat = createFieldMapper({ getter: 'chat/getField', setter: 'chat/setField' })

export default {
  components: {
    InputIconToggle,
    PerfectScrollbar,
    ChatCard,
    ChatCommand,
    ChatAutoReply,
  },
  setup() {
    const currentTab = ref(0)

    // search and result
    const searchQuery = ref('')
    const loadingSearch = ref(false)
    const searchResult = ref({
      chats: [],
      rooms: [],
    })

    const searchChat = useDebounceFn(data => {
      if (data && data.length > 2) {
        currentTab.value = 1
        loadingSearch.value = true

        apolloClient.query({})
      } else {
        currentTab.value = 0
        searchResult.value = {
          chats: [],
          rooms: [],
        }
        loadingSearch.value = false
      }
    }, 1000)

    // filter chat
    const showFilter = ref(false)
    const filterChat = ref({
      agentId: null,
      custId: null,
      statusId: null,
    })
    const state = {
      ...useFieldChat(['loadingChats', 'chatRoomList', 'statusRoomOptions', 'activeChat']),
    }

    // Perfect scrollbar options
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: true,
      wheelSpeed: 0.7,
    }

    const isChatActive = customerId => {
      if (!state.activeChat.value) return false

      return state.activeChat.value.contact.id === customerId
    }

    return {
      currentTab,

      searchQuery,
      loadingSearch,
      searchResult,
      showFilter,
      filterChat,
      searchChat,

      state,

      perfectScrollbarOptions,
      isChatActive,

      icons: {
        mdiMagnify,
        mdiFilterVariant,
        mdiClose,
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss">
.top-fixed {
  position: absolute;
  top: 0;
  z-index: 2;
}
</style>
