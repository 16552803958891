<template>
  <v-card
    height="73vh"
    class="pa-3 d-flex flex-column"
  >
    <v-icon
      class="ml-auto"
      @click="$emit('close')"
    >
      {{ icons.mdiClose }}
    </v-icon>
    <v-slide-y-transition
      mode="out-in"
    >
      <v-img
        v-if="activePreview.file.type.includes('image')"
        :key="activePreview.file.name"
        max-height="400"
        max-width="100%"
        contain
        class="mt-auto"
        :src="activePreview.preview"
      />
      <v-card
        v-else
        :key="activePreview.file.name"
        outlined
        height="320"
        width="320"
        class="mt-auto mx-auto"
      >
        <div
          class="d-flex flex-column h-full w-full justify-center align-center"
        >
          <v-icon size="60">
            {{ icons.mdiFile }}
          </v-icon>
          <span class="text--disabled text-truncate mt-4">
            {{ ellipsis(activePreview.file.name, 36) }}
          </span>
        </div>
      </v-card>
    </v-slide-y-transition>
    <v-sheet
      max-width="600px"
      width="100%"
      class="mx-auto mt-auto mb-2"
    >
      <v-form
        autocomplete="off"
        class="d-flex align-center"
        @submit.prevent="$emit('send')"
      >
        <v-text-field
          v-model="inputData"
          placeholder="Keterangan"
          outlined
          dense
          rounded
          class="mt-4"
        />
      </v-form>
    </v-sheet>
    <v-divider class="mb-4" />
    <div class="d-flex align-center">
      <div class="mr-auto" />
      <XyzTransitionGroup
        appear
        xyz="fade down-4 out-delay-0 stagger-2 out-stagger-0"
        class="d-flex"
      >
        <v-card
          v-for="(doc, index) in state.documents.value"
          :key="index"
          :height="$vuetify.breakpoint.xsOnly ? 60 : 80"
          :width="$vuetify.breakpoint.xsOnly ? 60 : 80"
          outlined
          class="d-flex align-center justify-center mx-1"
          @click="activeIndex = index"
        >
          <v-img
            v-if="doc.file.type.includes('image')"
            :height="$vuetify.breakpoint.xsOnly ? 60 : 80"
            :width="$vuetify.breakpoint.xsOnly ? 60 : 80"
            class="rounded"
            :src="doc.preview"
          />
          <div
            v-else
            class="d-flex flex-column h-full w-full justify-center align-center"
          >
            <v-icon size="36">
              {{ icons.mdiFile }}
            </v-icon>
          </div>
          <div class="close-attachment">
            <v-btn
              icon
              elevation="2"
              x-small
              style="background-color: #fff"
              @click.stop="$emit('delete', index)"
            >
              <v-icon>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </div>
        </v-card>
      </XyzTransitionGroup>
      <v-btn
        fab
        color="primary"
        class="ml-auto"
        :small="$vuetify.breakpoint.xsOnly"
        :loading="loading"
        @click="$emit('send')"
      >
        <v-icon>
          {{ icons.mdiSend }}
        </v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { ref, computed, watchEffect } from '@vue/composition-api'
import { mdiClose, mdiSend, mdiFile } from '@mdi/js'
import { createFieldMapper } from 'vuex-use-fields'
import { ellipsis } from '@core/utils/filter'

const useFieldChat = createFieldMapper({ getter: 'chat/getField', setter: 'chat/setField' })

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const inputData = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })

    const activeIndex = ref(0)
    const state = {
      ...useFieldChat(['documents']),
    }
    const activePreview = computed(() => state.documents.value[activeIndex.value])

    watchEffect(() => {
      if (state.documents.value.length <= activeIndex.value) {
        activeIndex.value = state.documents.value.length - 1
      }
    })

    return {
      inputData,
      activeIndex,
      state,
      activePreview,

      ellipsis,

      icons: {
        mdiClose,
        mdiSend,
        mdiFile,
      },
    }
  },
}
</script>

<style lang="scss">
.close-attachment {
  position: absolute;
  right: 2px;
  top: 2px;
}
</style>
