<template>
  <div
    class="chat-contact rounded-lg pa-3 cursor-pointer"
    :class="{ 'bg-gradient-primary active-chat-contact': isActive }"
    v-on="$listeners"
  >
    <div class="d-flex align-center">
      <v-avatar
        size="38"
        class="v-avatar-light-bg me-3"
        :class="[{ 'bg-static-white': isActive }]"
      >
        <v-img
          v-if="data.profile.photo"
          :src="data.profile.photo"
        />
        <span v-else>{{ avatarText(data.profile.customer ? data.profile.customer.name : data.profile.name) }}</span>
      </v-avatar>
      <div class="flex-grow-1 overflow-hidden">
        <p class="mb-0 font-weight-medium text--primary">
          {{ data.profile.customer ? data.profile.customer.name : data.profile.name }}
        </p>
        <p
          v-if="data.lastChat"
          class="text--disabled mb-0 text-truncate"
        >
          {{ data.lastChat.type === 'text' ? data.lastChat.content : data.lastChat.type }}
        </p>
      </div>
      <div
        v-if="data.lastChat"
        class="chat-meta d-flex flex-column align-self-stretch align-end"
      >
        <p class="text-caption text-no-wrap mb-0">
          {{ formatDateToMonthShort(data.lastChat.created_at, { hour: 'numeric', minute: 'numeric', hour12: false }) }}
        </p>
        <v-badge
          v-if="data.unreadMsg"
          inline
          class="number-badge"
          :content="data.unreadMsg"
          color="warning"
        />
      </div>
    </div>
    <div class="d-flex align-center mt-2">
      <img
        class="mr-1"
        height="16"
        :src="data.workspaceToken.platform.logo"
      >
      <p class="mb-0 text-caption">
        {{ data.workspaceToken.connection_name }}
      </p>
      <v-chip
        x-small
        :color="isActive ? 'white' : 'primary'"
        outlined
        class="font-medium py-2 ml-auto rounded-pill text-capitalize"
      >
        {{ data.status.name }}
      </v-chip>
    </div>
  </div>
</template>

<script>
import { formatDateToMonthShort, avatarText } from '@core/utils/filter'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { formatDateToMonthShort, avatarText }
  },
}
</script>

<style></style>
