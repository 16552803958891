import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import Intersect from 'vuetify/lib/directives/intersect';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{def: Scroll,name:"scroll",rawName:"v-scroll:#chatList",value:(_vm.listenScrollFetchMore),expression:"listenScrollFetchMore",arg:"#chatList"}],staticClass:"ps-chat-log h-full chat-log pa-5",attrs:{"id":"chatList"}},[(_vm.loading)?_c('div',{staticClass:"d-flex align-center justify-center mt-4",staticStyle:{"height":"60vh"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"48","color":"primary"}})],1):_c('div',[_c('div',{staticClass:"d-flex my-2",staticStyle:{"min-height":"36px"}},[(_vm.loadingFetchMore)?_c(VProgressCircular,{staticClass:"mx-auto",attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1),_vm._l((_vm.formattedChatData.formattedChatLog),function(msgGrp,index){return _c('div',{key:msgGrp.sender + String(index)},[(index === 0 || _vm.formattedChatData.formattedChatLog[index === 0 ? 0 : index - 1].date !== msgGrp.date)?_c('div',{staticClass:"d-flex justify-center pt-2 pb-4"},[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(msgGrp.date))])]):_vm._e(),_c('div',{staticClass:"chat-group d-flex align-start",class:[
          { 'flex-row-reverse': msgGrp.sender === 1 },
          { 'mb-8': _vm.formattedChatData.formattedChatLog.length - 1 !== index } ]},[(msgGrp.sender === 1)?_c('div',{staticClass:"chat-avatar ms-2"},[_c(VAvatar,{attrs:{"size":"38","color":"v-avatar-light-bg"}},[_c('span',[_vm._v(_vm._s(_vm.avatarText(_vm.state.activeChat.value.data.workspaceToken.connection_name)))])])],1):_vm._e(),_c('div',{staticClass:"chat-body d-inline-flex flex-column",class:msgGrp.sender === 1 ? 'align-end' : 'align-start'},[(msgGrp.sender === 1)?_c('span',{staticClass:"ms-auto text-caption mb-2",class:msgGrp.assigned_to && 'cursor-pointer',on:{"click":function($event){msgGrp.assigned_to && _vm.$router.push({
              name: 'user-detail',
              params: { id: msgGrp.assigned_to.id }
            })}}},[_vm._v(_vm._s(_vm.state.activeChat.value.data.workspaceToken.connection_name)+" "+_vm._s((" " + (msgGrp.assigned_to ? "/ " + msgGrp.assigned_to.name: ''))))]):_vm._e(),_vm._l((msgGrp.messages),function(msgData,msgIndex){return _c('div',{key:msgData.time,attrs:{"id":("chat-" + (msgData.id))}},[(msgData.type === 'text')?_c('p',{staticClass:"chat-content py-3 px-4 elevation-1",class:[
                msgGrp.sender === 2 ? 'bg-card chat-left' : 'primary white--text chat-right',
                msgGrp.messages.length - 1 !== msgIndex ? 'mb-2' : 'mb-1' ]},[_vm._v(" "+_vm._s(msgData.msg)+" ")]):(msgData.type === 'photo')?_c('div',{staticClass:"chat-content elevation-1",class:[
                msgGrp.sender === 2 ? 'bg-card chat-left' : 'primary white--text chat-right',
                msgGrp.messages.length - 1 !== msgIndex ? 'mb-2' : 'mb-1' ]},[_c(VImg,{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.fetchDocument(msgData)),expression:"fetchDocument(msgData)"}],staticClass:"rounded-t cursor-pointer",class:{ 'rounded-b': !msgData.caption },attrs:{"width":"300px","min-height":"200px","src":msgData.msg},on:{"click":function($event){return _vm.previewImage(msgData.msg)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":msgGrp.sender === 2 ? 'primary' : 'white'}})],1)]},proxy:true}],null,true)}),(msgData.caption)?_c('div',{staticClass:"px-4 py-2"},[_vm._v(" "+_vm._s(msgData.caption)+" ")]):_vm._e()],1):(msgData.type === 'document')?_c(VCard,{staticClass:"chat-content elevation-1 px-4 py-2 d-flex align-center",class:[
                msgGrp.sender === 2 ? 'bg-card chat-left' : 'primary white--text chat-right',
                msgGrp.messages.length - 1 !== msgIndex ? 'mb-2' : 'mb-1' ],on:{"click":function($event){return _vm.openDocument(msgData.msg)}}},[_c(VIcon,{staticClass:"mr-2",class:msgGrp.sender === 2 ? '' : 'white--text',attrs:{"size":"36px"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFile)+" ")]),_c('div',{class:msgGrp.sender === 2 ? '' : 'white--text'},[_c('span',{staticClass:"text-subtitle-2 d-block"},[_vm._v(_vm._s(_vm.ellipsis(msgData.file_name, 24)))]),_c('span',{staticClass:"text-caption"},[_vm._v("Document Details")])]),_c(VIcon,{staticClass:"ml-4",class:msgGrp.sender === 2 ? 'text--disabled' : 'white--text',attrs:{"size":"24px"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")])],1):_vm._e()],1)}),_c('div',{class:{ 'text-right': msgGrp.sender === 1 }},[_c('span',{staticClass:"text-xs me-1 text--disabled"},[_vm._v(_vm._s(_vm.formatDate(msgGrp.messages[msgGrp.messages.length - 1].time, { hour: 'numeric', minute: 'numeric' })))]),(msgGrp.sender === 1)?_c(VIcon,{attrs:{"size":"16","color":_vm.resolveFeedbackIcon(msgGrp.messages[msgGrp.messages.length - 1].feedback).color}},[_vm._v(" "+_vm._s(_vm.resolveFeedbackIcon(msgGrp.messages[msgGrp.messages.length - 1].feedback).icon)+" ")]):_vm._e()],1)],2)])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }